import { stonkClient } from "@/Utils/stonkClient";
import Edit from "@/assets/Edit";
import CopyTooltip from "@/components/CopyTooltip";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import useWallet from "@/hooks/useWallet";
import { cn } from "@/lib/utils";
import { CopyIcon, InstagramLogoIcon } from "@radix-ui/react-icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io5";

type Props = {
  userAddress: `0x${string}`;
};

const socialPlatformRegax: Record<string, RegExp> = {
  X: /https:\/\/x\.com\/[A-Za-z0-9_]+\/status\/\d+/,
  Youtube: /https:\/\/(www|m)\.youtube\.com\/post\/[A-Za-z0-9_-]+(\?.*)?/,
  Instagram:
    /https:\/\/www\.instagram\.com\/[A-Za-z0-9_.]+\/p\/[A-Za-z0-9_-]+\/?(\?.*)?/,
};

const socialDomainList: Record<string, string> = {
  X: "x.com",
  Instagram: "instagram.com",
  Youtube: "youtube.com",
};
const DomainToSocailList: Record<string, string> = {
  "x.com": "X",
  "instagram.com": "Instagram",
  "youtube.com": "Youtube",
};

const Socials = ({ userAddress }: Props) => {
  const queryClient = useQueryClient();
  const wallet = useWallet();

  const { data: knownSocials } = useQuery<SocialConnectionProps[]>({
    queryKey: ["user", userAddress, "kol"],
    queryFn: async () => {
      const res = await stonkClient(`user/${userAddress}/kol/`, {
        params: { user_signature: await wallet?.getSignature() },
      });
      return res.data.data;
    },
  });

  const submitKOL = useMutation({
    mutationKey: ["user", userAddress, "kol"],
    mutationFn: async ({ platform, url }: SocialConnectionProps) => {
      const res = await stonkClient.post(`user/${userAddress}/kol/`, null, {
        params: {
          platform,
          url,
          user_signature: await wallet?.getSignature(),
        },
      });
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user", userAddress, "kol"] });
    },
  });

  const handleSubmit = ({
    platform,
    url,
  }: {
    platform: string;
    url: string;
  }) => {
    platform = socialDomainList[platform];
    submitKOL.mutate({ platform, url, status: "Under Review" });
  };

  const allSocials = useMemo(() => {
    const _knownSocials =
      knownSocials?.map((social) => {
        return {
          ...social,
          platform: DomainToSocailList[social.platform],
        };
      }) || [];
    ["X", "Instagram", "Youtube"].forEach((platform) => {
      if (!_knownSocials.some((social) => social.platform === platform)) {
        _knownSocials.push({
          platform,
          url: "",
          status: "Not Connected",
        });
      }
    });
    return _knownSocials;
  }, [knownSocials]);

  return (
    <div className="flex flex-col items-center text-md">
      <div className="pl-4">
        <ol className="list-outside list-decimal">
          <li className="">
            <p>
              Verify your social accounts by making a post with the following
              text
            </p>
            <div className="m-2 flex min-w-0 items-end gap-2 rounded-lg bg-[#1A1A1A] p-3 text-sm sm:text-md">
              <p>
                Verifying my account on product.fun with the address{" "}
                <span className="break-words">{userAddress}</span>
              </p>
              <span className="cursor-pointer">
                <CopyTooltip
                  tooltipText="Copy"
                  textToCopy={`Verifying my account on product.fun with the address ${userAddress}`}
                >
                  <CopyIcon />
                </CopyTooltip>
              </span>
            </div>
          </li>
          <li className="">
            Paste the link below with the social account you are trying to
            connect
          </li>
        </ol>
      </div>
      {/* connected social */}
      <div className="mt-5 flex w-full flex-col items-center">
        <h3 className="mb-3 mr-auto text-md text-white">Connected Socials</h3>
        <div className="mb-12 flex w-full flex-col gap-6 bg-[#1A1A1A] p-4 sm:w-5/6">
          {allSocials.map((social) => (
            <SocialConnection
              key={social.platform}
              platform={social.platform}
              url={social.url}
              status={social.status}
              handleSubmit={handleSubmit}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

type SocialConnectionProps = {
  platform: string;
  url: string;
  status: "Verified" | "Rejected" | "Under Review" | "Not Connected";
};
const icons: { [key: string]: ReactElement } = {
  X: <FaXTwitter />,
  Instagram: <InstagramLogoIcon />,
  Youtube: <IoLogoYoutube />,
};

const SocialConnection = ({
  platform,
  url,
  status,
  handleSubmit,
}: SocialConnectionProps & {
  handleSubmit: (args: { platform: string; url: string }) => void;
}) => {
  const [input, setInput] = useState(url);
  const [isEditing, setIsEditing] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);
  useEffect(() => {
    if (!socialPlatformRegax[platform].test(input)) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  }, [isEditing, platform, input]);

  return (
    <div>
      <div className="flex w-full items-center gap-4">
        <div className="flex h-6 w-6 min-w-0 flex-none items-center">
          {icons[platform]}
        </div>
        {status === "Not Connected" || isEditing ? (
          <Input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={cn("flex-1", {
              "border-red-500": isInvalid,
              "border-gray-700": !isInvalid,
            })}
          />
        ) : (
          <div className="min-w-0 flex-1 overflow-clip">
            <a href={url} target="_blank">
              <p className="truncate">{url}</p>
            </a>
          </div>
        )}
        {status === "Not Connected" && (
          <Button
            variant={"outline"}
            size={"md"}
            onClick={() => handleSubmit({ platform, url: input })}
            disabled={isInvalid}
          >
            Submit
          </Button>
        )}
        {status === "Verified" && (
          <div className="flex flex-none items-center gap-4">
            <Button
              variant={"outline"}
              className="bg-[#29AE37]"
              disabled={true}
              size={"md"}
            >
              Verified
            </Button>
          </div>
        )}
        {(status === "Under Review" || status === "Rejected") &&
          (isEditing ? (
            <div className="flex items-center gap-4">
              <Button
                variant={"outline"}
                size={"md"}
                onClick={() => {
                  handleSubmit({ platform, url: input });
                  setIsEditing(false);
                }}
                disabled={isInvalid}
              >
                Submit
              </Button>
              <Button
                variant={"outline"}
                size={"md"}
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <Button
                variant={"ghost"}
                className={cn({
                  "bg-[#EFD169] text-black": status === "Under Review",
                  "bg-[#a22828] text-white": status === "Rejected",
                })}
                disabled={true}
                size={"md"}
              >
                {status}
              </Button>
              <div
                className="cursor-pointer"
                onClick={() => setIsEditing(true)}
              >
                <Edit />
              </div>
            </div>
          ))}
      </div>
      {input && isInvalid && (
        <p className="ml-12 mt-1 text-sm text-red">
          Not a valid {platform} post link
        </p>
      )}
    </div>
  );
};

export default Socials;
