import { mockUsdcAbi, routerAbi } from "@/ABIs/Router";
import WalletIcon from "@/assets/WalletIcon";
import { FormatTokenAmount } from "@/components/FormatAmount";
import Image from "@/components/Image";
import { Button } from "@/components/ui/button";
import { InvisibleInput } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Toggle } from "@/components/ui/toggle";
import { Contracts } from "@/ContractsConfig";
import useContract from "@/hooks/useContract";
import useGenericContract from "@/hooks/useGenericContract";
import useWallet from "@/hooks/useWallet";
import { cn } from "@/lib/utils";
import { TokenData } from "@/types/BaseTypes";
import displayTruncated from "@/Utils/displayTruncated";
import { getInitialBuyPrice } from "@/Utils/PlatformMath/getTokenomics";
import { usePrivy } from "@privy-io/react-auth";
import { useQueryClient } from "@tanstack/react-query";
import { readContract } from "@wagmi/core";
import Big from "big.js";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useBalance, useConfig, useReadContract } from "wagmi";
interface BuySellProps {
  tokenData: TokenData;
  onBuy: () => void;
}
Big.NE = -20;

const BuySellTab = ({ tokenData, onBuy }: BuySellProps) => {
  const [activeTab, setActiveTab] = useState<"buy" | "sell">("buy");
  const [tokenAmount, setTokenAmount] = useState("");
  const [usdcAmount, setUsdcAmount] = useState("0");
  const [buySellError, setBuySellError] = useState({ buy: "", sell: "" });
  const [slippage, setSlippage] = useState({ selected: "0.5", value: 0.5 });

  const queryClient = useQueryClient();
  const config = useConfig();
  const account = useWallet() ?? { address: undefined };
  const tokenBalance = useBalance({
    address: account.address,
    token: tokenData.id,
  });
  const usdcBalance = useBalance({
    address: account.address,
    token: Contracts.usdc,
  });
  const { login } = usePrivy();

  const invalidateData = () => {
    queryClient.invalidateQueries({
      queryKey: ["token", tokenData.id],
    });
    queryClient.invalidateQueries({
      queryKey: ["user", account?.address, "reflections"],
    });
    queryClient.invalidateQueries({
      queryKey: ["token", tokenData.id, "holders"],
    });
    queryClient.invalidateQueries({ queryKey: ["token", tokenData.id] });
    queryClient.invalidateQueries({
      queryKey: ["token", tokenData.id, "trades"],
    });
    onBuy();
  };

  const { data: allowance } = useReadContract({
    address: Contracts.usdc,
    abi: mockUsdcAbi,
    functionName: "allowance",
    args: [account.address as `0x${string}`, Contracts.router],
  });

  const { callContract: callApprove, isConfirmed: isApproved } =
    useGenericContract({
      toastTitle: "Approving USDC",
    });

  const { callContract: callBuyToken, isSubmitting: isBuying } = useContract({
    toastTitle: "Buying Tokens",
    onSubmit: () => {
      setTokenAmount("");
    },
    onConfirm: () => {
      tokenBalance.refetch();
      usdcBalance.refetch();
    },
    onConfirmDelayed: () => queryClient.invalidateQueries(),
  });
  const { callContract: callSellToken, isSubmitting: isSelling } = useContract({
    toastTitle: "Selling Tokens",
    onSubmit: () => {
      setTokenAmount("");
    },
    onConfirm: () => {
      tokenBalance.refetch();
      usdcBalance.refetch();
    },
    onConfirmDelayed: () => queryClient.invalidateQueries(),
  });

  const getUSDCAmount = async (tokenAmount: string, type: "buy" | "sell") => {
    if (!tokenAmount) {
      setUsdcAmount("0");
      return;
    }
    const tokens = BigInt(Big(tokenAmount).times(1e8).toFixed(0));
    try {
      let wei = 0n;
      if (tokenData.created === false && type === "buy") {
        wei = BigInt(getInitialBuyPrice(tokenData.curveType, Number(tokens)));
      } else {
        if (type === "buy") {
          [, wei] = await readContract(config, {
            abi: routerAbi,
            address: Contracts.router,
            functionName: "getBuyPriceAfterFee",
            args: [tokenData.id, tokens],
          });
        } else {
          wei = await readContract(config, {
            abi: routerAbi,
            address: Contracts.router,
            functionName: "getSellPriceAfterFee",
            args: [tokenData.id, tokens],
          });
        }
      }
      console.log("amount", wei);
      const usdc = Big(wei.toString()).div(1e6).toFixed(18);
      setBuySellError((prev) => ({ ...prev, buy: "", sell: "" }));
      setUsdcAmount(usdc);
    } catch (e) {
      if (typeof e === "object") console.log({ ...e });
      else console.log(e);
      setBuySellError((prev) => ({ ...prev, buy: "Invalid amount" }));
    }
  };

  const debouncedGetEthAmount = useCallback(debounce(getUSDCAmount, 500), [
    tokenData,
  ]);

  useEffect(() => {
    debouncedGetEthAmount(tokenAmount, activeTab);
  }, [tokenAmount, debouncedGetEthAmount, activeTab]);

  const buyWithApproval = async () => {
    if (!account.address) {
      login();
      return;
    }
    if (allowance === undefined) return;
    const uintMax = 2n ** 256n - 1n;
    if (allowance !== uintMax) {
      console.log("allowance not available, asking for approval");
      const tx = await callApprove({
        abi: mockUsdcAbi,
        functionName: "approve",
        args: [Contracts.router, uintMax],
        address: Contracts.usdc,
      });
    } else {
      buyHandler();
    }
  };

  useEffect(() => {
    if (isApproved) {
      buyHandler();
    }
  }, [isApproved]);

  const buyHandler = async () => {
    const tokens = BigInt(Big(tokenAmount).times(1e8).toFixed(0));
    const usdc = BigInt(Big(usdcAmount).times(1e18).toFixed(0));

    if (activeTab === "buy") {
      const ethWithSlippage =
        (usdc * BigInt(Math.round(1 + slippage.value) * 1000)) / BigInt(1000) +
        1n;
      if (tokenData.created) {
        callBuyToken("buyToken", [tokenData.id, tokens, ""]);
      } else {
        const argsOpt = {
          name: tokenData.name,
          imageDescriptionHash: tokenData.hash,
          creator: tokenData.creator_address,
          graduationTokenAmount:
            BigInt(Math.round(tokenData.graduationTokenAmount / 1e8)) *
            BigInt(1e8),
          curveType: tokenData.curveType,
          symbol: tokenData.ticker,
        };
        callBuyToken(
          "createAndBuyToken",
          [argsOpt, tokens, ""]
          // ethWithSlippage + BigInt(2e14)
        );
      }
      // callBuyToken("buyToken", [tokenData.id, tokens], BigInt(1 || 100 * 1e18));
    } else {
      const ethWithSlippage = 0n;
      // (usdc * BigInt(Math.round(1 - slippage.value) * 1000)) / BigInt(1000) -
      // BigInt(1);
      callSellToken("sellToken", [tokenData.id, tokens, ethWithSlippage, ""]);
    }
  };

  return (
    <div className="flex flex-col gap-2 rounded-sm bg-b1 px-2 py-4">
      <div className="relative flex items-center justify-between">
        <div className="flex w-full justify-between">
          <button
            className={cn(
              "w-1/2 rounded-l-lg bg-background py-[4px] font-medium text-secondary-foreground",
              activeTab == "buy" && "bg-buy-green text-primary-foreground"
            )}
            value="buy"
            onClick={() => setActiveTab("buy")}
          >
            Buy
          </button>
          <button
            className={cn(
              "w-1/2 rounded-r-lg bg-background py-[4px] font-medium text-secondary-foreground",
              activeTab == "sell" && "bg-sell-red text-primary-foreground"
            )}
            onClick={() => setActiveTab("sell")}
            value="sell"
            //   onClick={}
          >
            Sell
          </button>
        </div>
      </div>

      <div
        className={cn(
          "box-content rounded-lg border-[2px] border-transparent bg-background p-2 px-3 transition-all",
          {
            "border-red": buySellError.buy,
          }
        )}
      >
        <div className="flex items-center justify-between text-sm text-subtext">
          {/* <div className="">{activeTab === "buy" ? "Buy" : "Sell"}</div> */}
          {/* <div className="flex items-center gap-[3px]">
            <WalletIcon className="w-[10px] h-[10px]" /> 10k tokens
          </div> */}
        </div>
        <div className="flex items-center justify-between gap-1">
          <InvisibleInput
            onChange={(e) => {
              if (/^\d*\.?\d{0,8}$/.test(e.target.value))
                setTokenAmount(e.target.value);
            }}
            name="buy"
            className="text-lg placeholder:text-lg"
            type="number"
            inputMode="decimal"
            placeholder="Number of tokens"
            min="0"
            max="1000000000"
            value={tokenAmount.toString()}
          />
          {/* <CurrencyChip name={tokenData.ticker} /> */}
          <div className="flex items-center gap-[4px] rounded-md bg-gray-900 p-1 py-[2px] pr-[29px] text-lg font-medium uppercase text-secondary-foreground">
            <Image
              variant="profile"
              alt="Token image"
              src={tokenData.token_image}
              isNsfw={tokenData.token_image_is_nsfw}
              className="h-6 w-6 rounded-full"
              address="0x0"
            />
            <div className="">
              {displayTruncated(tokenData.ticker || "", 5)}
            </div>
          </div>
        </div>
        <div className="text-sm text-secondary-foreground">
          <span
            className={cn(
              "flex items-center justify-between text-subtext",
              buySellError.buy && "text-red"
            )}
          >
            {/* {buySellError.buy || "Tokens worth " + compactUSD(ethAmount)} */}
            {!buySellError.buy ? (
              <div className="flex items-center gap-[3px]">
                <WalletIcon className="h-[10px] w-[10px]" />{" "}
                <FormatTokenAmount token={tokenBalance.data?.value} /> $
                {displayTruncated(tokenData.ticker, 10)}
              </div>
            ) : null}
          </span>
        </div>{" "}
      </div>

      <div className="ml-2 flex gap-2 text-md">
        <button
          className="rounded bg-background px-2"
          onClick={() => setTokenAmount("1000000")}
        >
          1M
        </button>
        <button
          className="rounded bg-background px-2"
          onClick={() => setTokenAmount("10000000")}
        >
          10M
        </button>
        <button
          className="rounded bg-background px-2"
          onClick={() => setTokenAmount("100000000")}
        >
          100M
        </button>
        <Popover>
          <PopoverTrigger asChild>
            <button className="ml-auto cursor-pointer rounded-sm p-1 text-sm underline">
              Slippage
            </button>
          </PopoverTrigger>
          <PopoverContent>
            <div className="flex flex-wrap items-center gap-4">
              <p>Slippage</p>
              <div className="flex items-center gap-1">
                <Toggle
                  pressed={slippage.selected === "0.25"}
                  onPressedChange={(pressed) =>
                    pressed
                      ? setSlippage({ selected: "0.25", value: 0.25 })
                      : null
                  }
                  variant="outline"
                  size={"sm"}
                >
                  0.25%
                </Toggle>
                <Toggle
                  pressed={slippage.selected === "0.5"}
                  onPressedChange={(pressed) =>
                    pressed
                      ? setSlippage({ selected: "0.5", value: 0.5 })
                      : null
                  }
                  variant="outline"
                  size={"sm"}
                >
                  0.5%
                </Toggle>
                <Toggle
                  pressed={slippage.selected == "1"}
                  onPressedChange={(pressed) =>
                    pressed ? setSlippage({ selected: "1", value: 1 }) : null
                  }
                  variant="outline"
                  size={"sm"}
                >
                  1%
                </Toggle>
                <Toggle
                  variant="outline"
                  size={"sm"}
                  className="rounded-xs border-gray-700"
                  pressed={slippage.selected == "custom"}
                  onPressedChange={(pressed) =>
                    pressed
                      ? setSlippage({
                          selected: "custom",
                          value: slippage.value,
                        })
                      : null
                  }
                >
                  <input
                    placeholder="0.5"
                    type="number"
                    className="mr-2 h-fit w-10 border-0 bg-transparent p-1 focus:outline-0"
                    // disabled={slippage.selected != "custom"}
                    value={slippage.value}
                    onChange={(e) =>
                      setSlippage({
                        selected: "custom",
                        value: +e.target.value,
                      })
                    }
                  />
                  %
                </Toggle>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>

      {/* <div className="relative flex justify-center z-10 w-full h-[6px] ">
        <button
          className="absolute top-0 z-10 p-2 transition-all -translate-x-1/2 -translate-y-1/2 rounded-full swap-border border- bg-primary left-1/2 text-secondary-foreground hover:brightness-125 hover:scale-110 active:scale-90"
          onClick={() => {
            setActive((e) => (e == "buy" ? "sell" : "buy"));
          }}
        >
          <IoSwapVerticalOutline />
        </button>
      </div> */}

      <div className="px-2 text-secondary">
        <div className="flex items-center justify-between text-sm">
          {/* <span>{activeTab === "buy" ? "You pay" : "You get"}</span> */}
        </div>

        <div className="flex justify-between">
          <span>
            {buySellError.buy
              ? "0"
              : Big(
                  Big(Big(usdcAmount).toPrecision(6, 3)).toFixed(18, 3)
                ).toString()}{" "}
            USDC
          </span>
          <span>
            {!!usdcBalance.data && (
              <div className="flex items-center gap-[3px] text-sm text-subtext">
                <WalletIcon className="h-[10px] w-[10px]" />{" "}
                <FormatTokenAmount
                  token={usdcBalance.data.value}
                  decimals={6}
                />{" "}
                USDC
              </div>
            )}
          </span>
        </div>
        {/* <div className="text-sm text-secondary-foreground">
          {buySellError.buy ? "$0" : compactUSD(usdcAmount)}
        </div> */}
      </div>

      <Button
        className={cn(
          "flex w-full justify-center rounded-sm p-4 py-3 text-center text-lg font-medium capitalize",
          {
            "bg-[#218661] text-primary-foreground": activeTab == "buy",
            "bg-red text-primary-foreground": activeTab == "sell",
          }
        )}
        size={"lg"}
        onClick={() => buyWithApproval()}
        disabled={isBuying || isSelling}
      >
        {!!account.address ? activeTab : "Connect Wallet"}
      </Button>
    </div>
  );
};

export { BuySellTab };
// const CurrencyChip = ({ name, image }: { name?: string; image?: string }) => {
//   return (
//     <div className="p-1  pr-[29px]  uppercase  text-secondary-foreground font-medium py-[2px] text-lg rounded-md bg-background flex items-center gap-[4px]">
//       {name === "ETH" ? (
//         <img
//           src={ethIcon}
//           className="w-[22px]  h-[22px] rounded-full object-contain"
//         />
//       ) : (
//         <Image variant="token" alt="Token image" isNsfw src={image} />
//       )}
//       <div className="">{displayTruncated(name || "", 5)}</div>
//     </div>
//   );
// };
