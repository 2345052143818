import LandingIllustration from "@/assets/landingIllustration.webp";
import LogoBig from "@/assets/logo_big.png";

const CreateLanding = () => {
  return (
    <div className="flex min-h-full flex-col items-center px-4 py-8 text-white">
      <img
        className="mb-4"
        src={LogoBig}
        alt="ProductDotFun Logo"
        width={150}
      />
      <div className="relative">
        {/* <div className="absolute flex -translate-x-8 -translate-y-8 -rotate-12 items-center gap-2">
          <div className="text-lg underline">for Builders</div>
          <span className="text-2xl">🏗️</span>
        </div> */}
        <h1 className="mb-2 text-center font-title text-2xl font-bold lg:text-4xl">
          Convert your product idea to tradable coins
        </h1>
      </div>
      <p className="mb-4 text-center text-base text-gray-400 lg:text-xl">
        Crowdfund your idea and build a community of early users and promoters
      </p>
      {/* <div className="relative flex items-center">
        <CurlyArrow className="absolute -translate-x-24 -rotate-12" />
        <Link to="/apps/top">
          <Button className="rounded-full bg-white text-black">
            Start Trading
          </Button>
        </Link>
      </div> */}
      <img src={LandingIllustration} width={300} alt="Landing Illustration" />

      <p className="mb-4 text-center text-base">
        DM{" "}
        <a href="https://x.com/sm_muon" target="_blank">
          <span className="text-blue-600">@sm_muon</span>
        </a>{" "}
        on X to get your product idea listed.
      </p>
      <p className="max-w-md text-center text-base text-gray-400">
        Note - Product can be at any stage - your profile, clarity of thought
        and long term vision matter more!
      </p>
    </div>
  );
};

export default CreateLanding;
