import { useToast } from "@/components/ui/use-toast";
import { useEffect } from "react";
import { ContractFunctionExecutionError, TransactionReceipt } from "viem";
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";

const useGenericContract = ({
  toastTitle,
  onSubmit,
  onConfirm,
  onConfirmDelayed,
}: {
  toastTitle: string;
  onSubmit?: (hash: `0x${string}`) => void;
  onConfirm?: (data: TransactionReceipt) => void;
  onConfirmDelayed?: (data: TransactionReceipt) => void;
}) => {
  const { toast } = useToast();

  const {
    writeContractAsync: callContract,
    data: txnHash,
    error,
    isPending: isSubmitting,
  } = useWriteContract({
    mutation: {
      onMutate: () => {
        toast({
          title: toastTitle,
          description: (
            <div className="flex items-baseline">
              Please confirm the transaction in your wallet
            </div>
          ),
        });
      },
      onSuccess: (data) => {
        if (onSubmit) onSubmit(data);
      },
    },
  });
  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    data,
  } = useWaitForTransactionReceipt({ hash: txnHash });

  // const callContract = async (
  //   args: Parameters<typeof writeContractAsync>[0]
  // ) => {
  //   toast({
  //     title: toastTitle,
  //     description: (
  //       <div className="flex items-baseline">
  //         Please confirm the transaction in your wallet
  //       </div>
  //     ),
  //   });
  //   const hash = await writeContractAsync(args);
  //   if (onSubmit) onSubmit(hash);
  //   return hash;
  // };

  useEffect(() => {
    if (error) {
      console.log({ ...error });
      const typedError = error as ContractFunctionExecutionError;
      let message = "";
      if (typedError.metaMessages && typedError.metaMessages.length > 2) {
        message = typedError.metaMessages[1].trim();
        if (message.startsWith("(") && message.endsWith(")")) {
          message = message.slice(1, -1);
        }
      }
      if (!message) {
        message = typedError.shortMessage || error.message;
      }
      toast({
        variant: "destructive",
        title: "Something went wrong",
        description: message,
      });
    }
  }, [error, toast]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (isConfirming) {
      toast({
        title: toastTitle,
        description: (
          <div className="flex items-baseline">
            Confirming transaction. Please wait.
          </div>
        ),
      });
    } else if (isConfirmed) {
      if (onConfirm) onConfirm(data);
      if (onConfirmDelayed) {
        timeoutId = setTimeout(() => {
          onConfirmDelayed(data);
        }, 2000);
      }
      toast({
        title: toastTitle,
        description: (
          <div className="flex items-baseline">
            Transaction confirmed.
            <br />
            {txnHash}
          </div>
        ),
      });
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isConfirmed, isConfirming, data]);

  return {
    callContract,
    isSubmitting,
    isConfirming,
    isConfirmed,
    toast,
    txnHash,
    data,
  };
};

export default useGenericContract;
