import CopyTooltip from "@/components/CopyTooltip";
import { FormatTokenAmount } from "@/components/FormatAmount";
import Image from "@/components/Image";
import TimeElapsed from "@/components/TimeElapsed";
import { Switch } from "@/components/ui/switch";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import useWallet from "@/hooks/useWallet";
import displayAddress from "@/Utils/displayAddress";
import { stonkClient } from "@/Utils/stonkClient";

import React, { useState } from "react";
import { Link } from "react-router-dom";

type Trade = {
  id: string;
  txHash: `0x${string}`;
  timestamp: number;
  tokenAddress: string;
  userAddress: `0x${string}`;
  price: number;
  amount: number;
  isBuy: boolean;
  protocolFee: number;
  creatorFee: number;
  lastBuyFee: number;
  username: string;
  user_profile_image: string;
  is_nsfw: boolean;
};

type Props = {
  tokenAddress: string;
  tokenTicker: string;
};

const Trades = ({ tokenAddress, tokenTicker }: Props) => {
  const [filterByFollowing, setFilterByFollowing] = useState(false);
  const wallet = useWallet();

  const {
    data: trades,
    loadMoreRef,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteScroll({
    queryKey: ["token", tokenAddress, "trades", String(filterByFollowing)],
    queryFn: async ({ pageParam }) => {
      const res = await stonkClient(`token/${tokenAddress}/trades/`, {
        params: {
          page: pageParam,
          followed_by_address: filterByFollowing ? wallet?.address : undefined,
        },
      });
      return res.data.data as Trade[];
    },
    refetchInterval: import.meta.env.VITE_DISABLE_POOLING ? undefined : 1000,
  });

  return (
    <div className="flex flex-col gap-5">
      <div
        className="flex cursor-pointer items-center gap-3"
        onClick={() => setFilterByFollowing(!filterByFollowing)}
      >
        <p className="text-md">Filter by following</p>
        <Switch checked={filterByFollowing} />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="mr-10">Account</TableHead>
            <TableHead>Type</TableHead>
            <TableHead className="text-right">USDC</TableHead>
            <TableHead className="text-right">${tokenTicker}</TableHead>
            <TableHead className="text-right">Date</TableHead>
            <TableHead className="text-right">Transaction</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {!!trades &&
            trades.pages.map((group, i) => (
              <React.Fragment key={i}>
                {group.map((trade) => (
                  <TableRow key={trade.txHash}>
                    <TableCell className="font-medium">
                      <Link to={`/devs/${trade.userAddress}`}>
                        <div className="flex flex-wrap items-center">
                          <Image
                            className="h-6 w-6 rounded-full"
                            src={trade.user_profile_image}
                            alt="Profile image"
                            variant="profile"
                            address={trade.userAddress}
                            isNsfw={trade.is_nsfw}
                          />
                          <p className="px-1 hover:underline">
                            @{trade.username}
                          </p>
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {trade.isBuy ? (
                        <span className="text-buy-green">Buy</span>
                      ) : (
                        <span className="text-sell-red">Sell</span>
                      )}
                    </TableCell>
                    <TableCell className="text-right">
                      $<FormatTokenAmount token={trade.price} decimals={6} />
                    </TableCell>
                    <TableCell className="text-right">
                      <FormatTokenAmount token={trade.amount} />
                    </TableCell>
                    <TableCell className="text-right">
                      <TimeElapsed
                        eventTime={new Date(trade.timestamp * 1000)}
                      />{" "}
                      ago
                    </TableCell>
                    <TableCell>
                      {/* <span className="cursor-pointer"> */}
                      <div className="ml-auto w-fit">
                        <CopyTooltip
                          side="left"
                          tooltipText="Copy"
                          textToCopy={trade.txHash ?? ""}
                        >
                          <h5 className="cursor-pointer text-md text-subtext">
                            {displayAddress(trade.txHash)}
                          </h5>
                        </CopyTooltip>
                      </div>
                      {/* </span> */}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      <div ref={loadMoreRef} id="load-more" style={{ height: 1 }} />
      {isFetchingNextPage && <p>Loading more...</p>}
      {isLoading && !isFetchingNextPage && <p>Loading...</p>}
      {!!trades &&
        (trades.pages.length === 0 || trades?.pages[0].length === 0) && (
          <p className="text-center">Nothing here yet</p>
        )}
    </div>
  );
};

export default Trades;
