import * as React from "react";

function CurlyArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="83"
      height="67"
      viewBox="0 0 83 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.02329 28.1774L22.4244 49.8357L26.1963 40.1203C35.463 43.0041 45.6531 45.5339 55.217 42.9325C56.0507 42.7059 56.8495 42.43 57.615 42.1123C61.6891 43.7655 65.9608 44.9439 70.0588 46.5605C75.1693 48.5761 78.2395 40.6329 73.1325 38.6187C70.6427 37.6367 67.7616 36.8493 64.8923 35.9304C65.301 35.2351 65.6676 34.4959 65.9782 33.6999C67.7118 29.2589 66.2782 24.2719 63.6228 20.5501C61.0249 16.9087 56.5492 15.0501 52.1681 15.6292C46.258 16.4104 42.7413 21.7114 43.0596 27.3298C43.2376 30.4815 44.9132 33.2539 47.129 35.5219C41.1346 35.7125 34.9807 33.9729 29.2778 32.182L32.48 23.9358L5.02329 28.1774ZM53.5228 24.039C55.3554 23.6303 56.8631 25.4342 57.6169 26.9729C58.8281 29.4442 58.2641 31.2479 56.8753 32.5428C55.1543 31.4991 53.6306 30.2368 52.4472 28.6389C51.1099 26.8331 50.9978 24.6027 53.5228 24.039Z"
        fill="#E3E3E3"
      />
      <path
        d="M5.02329 28.1774L22.4244 49.8357L26.1963 40.1203C35.463 43.0041 45.6531 45.5339 55.217 42.9325C56.0507 42.7059 56.8495 42.4301 57.615 42.1123C61.6891 43.7655 65.9608 44.9439 70.0588 46.5605C75.1693 48.5761 78.2394 40.6329 73.1325 38.6187C70.6427 37.6367 67.7616 36.8493 64.8923 35.9304C65.301 35.2351 65.6676 34.4959 65.9782 33.6999C67.7118 29.2589 66.2782 24.2719 63.6228 20.5501C61.0249 16.9087 56.5492 15.0501 52.1681 15.6292C46.2579 16.4104 42.7413 21.7114 43.0596 27.3298C43.2376 30.4815 44.9132 33.2539 47.129 35.5219C41.1346 35.7125 34.9807 33.9729 29.2778 32.182L32.48 23.9358L5.02329 28.1774ZM53.5228 24.039C55.3554 23.6303 56.8631 25.4342 57.6169 26.9729C58.8281 29.4442 58.2641 31.2479 56.8753 32.5428C55.1543 31.4991 53.6306 30.2368 52.4472 28.6389C51.1099 26.8331 50.9978 24.6027 53.5228 24.039Z"
        stroke="#323232"
        strokeWidth="4.3365"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CurlyArrow;
