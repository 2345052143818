import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";
const override: React.CSSProperties = {
  // display: "block",
  margin: "0",
  marginRight: "5px",
  // borderColor: "red",
};

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-full whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 disabled:cursor-not-allowed ",
  {
    variants: {
      variant: {
        default:
          "bg-white text-accent-foreground shadow hover:brightness-110 active:brightness-90 text-md  px-4 py-2 w-fit",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-white bg-background shadow-sm hover:bg-white hover:border-white hover:text-accent-foreground rounded-full text-md",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        gradient:
          "bg-gradient-to-r from-accent to-accent-red text-accent-foreground rounded-full shadow hover:bg-gradient-to-r  hover:brightness-110 active:brightness-90 transition-all",
      },
      size: {
        default: "h-9 px-4 py-2 text-[16px] font-semibold",
        sm: "h-6 rounded-md px-3 text-[14px]",
        md: "h-8 px-3 text-md",
        lg: "h-10 rounded-md px-8 py-2 text-lg font-semibold",
        icon: "h-6 w-6",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, isLoading, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          "transition-all",
          buttonVariants({ variant, size, className }),
          isLoading && "cursor-not-allowed hover:brightness-100"
        )}
        onClick={
          isLoading
            ? (e) => {
                e.preventDefault();
              }
            : props.onClick
        }
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <div
            className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent"
            aria-label="Loading Spinner"
            data-testid="loader"
          ></div>
        ) : null}{" "}
        {props.children}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
