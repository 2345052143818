import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useState } from "react";

const CopyTooltip = ({
  children,
  tooltipText,
  textToCopy,
  side = "top",
}: {
  children: React.ReactNode;
  tooltipText: string;
  textToCopy: string;
  side?: "top" | "right" | "bottom" | "left";
}) => {
  const [tooltipContent, setTooltipContent] = useState(tooltipText);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy); // Replace with your actual text to copy
    setTooltipContent("Copied!");
    setIsTooltipOpen(true);

    // Keep the tooltip open for 5 seconds
    setTimeout(() => {
      setIsTooltipOpen(false);
      setTimeout(() => setTooltipContent(tooltipText), 100);
    }, 2000);
  };

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0} open={isTooltipOpen}>
        <TooltipTrigger asChild>
          <div
            onClick={handleCopyClick}
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() =>
              tooltipContent === tooltipText && setIsTooltipOpen(false)
            }
            className="inline-block w-fit"
          >
            {children}
          </div>
        </TooltipTrigger>
        <TooltipContent side={side}>
          <p>{tooltipContent}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default CopyTooltip;
