import { routerAbi } from "@/ABIs/Router";
import { FormatTokenAmount } from "@/components/FormatAmount";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import useContract from "@/hooks/useContract";
import useWallet from "@/hooks/useWallet";
import { TokenData } from "@/types/BaseTypes";
import displayAddress from "@/Utils/displayAddress";
import { generateHandle, reverseHandle } from "@/Utils/parseSocials";
import { stonkClient } from "@/Utils/stonkClient";
import { Cross1Icon } from "@radix-ui/react-icons";
import { useQuery } from "@tanstack/react-query";
import Big from "big.js";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { encodeFunctionData } from "viem";

type KOL = {
  kolAddress: `0x${string}`;
  tokenAmount: number;
  claimedAmount: number;
  kolId: string;
  username: string | null;
  image: string | null;
};

type NewAllocation = {
  url: string;
  handle: string;
  allocation: number;
  invalidUrl?: boolean;
};

type Props = {
  tokenAddress: `0x${string}`;
  tokenData: TokenData;
};

const KOLAllocation = ({ tokenAddress, tokenData }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState<NewAllocation[]>([
    { url: "", handle: "", allocation: 0 },
  ]);
  const [finalError, setFinalError] = useState<string | null>(null);
  const wallet = useWallet();

  const { data, refetch } = useQuery({
    queryKey: ["token", tokenAddress, "kol_allocation"],
    queryFn: async () => {
      const res = await stonkClient(`token/${tokenAddress}/kol_allocation/`);
      return res.data.data as KOL[];
    },
    refetchInterval: import.meta.env.VITE_DISABLE_POOLING ? undefined : 10000,
  });

  const { callContract } = useContract({
    toastTitle: "Allocating tokens",
    onConfirmDelayed: () => refetch(),
  });

  useEffect(() => {
    if (modalOpen) {
      setFormData([{ url: "", handle: "", allocation: 0 }]);
      setFinalError(null);
    }
  }, [modalOpen]);

  if (!tokenData.totalKolTokenAmount) tokenData.totalKolTokenAmount = 1;
  if (!tokenData.assignedKolTokenAmount) tokenData.assignedKolTokenAmount = 0;

  const unallocatedTokensAmount = (() => {
    let res = Big(tokenData.totalKolTokenAmount).sub(
      Big(tokenData.assignedKolTokenAmount)
    );
    return res.sub(res.mod(10));
  })();

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.target.name as "url" | "allocation";
    const updatedFormData = [...formData];
    if (name === "allocation") {
      updatedFormData[index][name] = parseFloat(event.target.value);
      const sum = updatedFormData.reduce(
        (agg, alloc) => agg + alloc.allocation,
        0
      );
      if (sum > unallocatedTokensAmount.div(1e8).toNumber()) {
        setFinalError("Total allocation exceeds unallocated tokens");
      } else {
        setFinalError(null);
      }
    } else {
      updatedFormData[index][name] = event.target.value;
    }
    setFormData(updatedFormData);
  };

  const handleBlur = (
    index: number,
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const url = event.target.value;
    const updatedFormData = [...formData];
    const handle = generateHandle(url);
    if (!handle) {
      updatedFormData[index].invalidUrl = true;
      updatedFormData[index].handle = "";
    } else {
      updatedFormData[index].invalidUrl = false;
      updatedFormData[index].handle = handle;
    }
    setFormData(updatedFormData);
  };

  const handleSubmit = async () => {
    if (formData.some((alloc) => alloc.invalidUrl || alloc.allocation === 0)) {
      return;
    }

    const encodedParams = formData.map((alloc) => {
      return encodeFunctionData({
        abi: routerAbi,
        functionName: "increaseKOLAllocation",
        args: [
          tokenAddress,
          alloc.handle,
          BigInt(Math.round(alloc.allocation * 1e8)),
        ],
      });
    });
    await callContract("multicall", [encodedParams]);
    setModalOpen(false);
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="w-full text-md">
          {!tokenData.created ? (
            <div className="text-center text-secondary-foreground">
              Coin not created onchain yet
            </div>
          ) : (
            <>
              Unallocated tokens:{" "}
              <FormatTokenAmount token={unallocatedTokensAmount.toNumber()} /> (
              {unallocatedTokensAmount
                .mul(100)
                .div(tokenData.totalKolTokenAmount)
                .toFixed(5)
                .replace(/\.?0+$/, "")}
              %)
            </>
          )}
        </div>
        {wallet?.address === tokenData.creator_address && (
          <Dialog open={modalOpen} onOpenChange={setModalOpen}>
            <DialogTrigger asChild>
              <Button
                variant={"default"}
                className="rounded-[6px]"
                size={"sm"}
                disabled={!tokenData.created}
              >
                Allocate
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogTitle>Allocate tokens to KOLs</DialogTitle>
              <DialogDescription>
                They can claim the allotted tokens once they have registered
                with us and the token has been listed on Uniswap.
              </DialogDescription>
              <div className="text-md">
                Unallocated tokens:{" "}
                {unallocatedTokensAmount
                  .div(1e8)
                  .toFixed(8, 0)
                  .replace(/\.?0+$/, "")}{" "}
                (
                {unallocatedTokensAmount
                  .mul(100)
                  .div(tokenData.totalKolTokenAmount)
                  .toFixed(5)
                  .replace(/\.?0+$/, "")}
                %)
              </div>
              {formData.map((alloc, index) => (
                <div key={index} className="flex justify-between gap-4">
                  <div>
                    <Label htmlFor="url" className="text-sm">
                      Social Profile URL
                    </Label>
                    <Input
                      name="url"
                      value={alloc.url}
                      onChange={(e) => handleChange(index, e)}
                      onBlur={(e) => handleBlur(index, e)}
                      type="url"
                      placeholder="https://x.com/KakarotBuilds"
                    ></Input>
                    {alloc.invalidUrl && (
                      <p className="text-sm text-red">Invalid Url</p>
                    )}
                    {alloc.handle && (
                      <p className="text-sm text-green">{alloc.handle}</p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="allocation" className="text-sm">
                      Allocation (Number of tokens)
                    </Label>
                    <Input
                      name="allocation"
                      value={alloc.allocation}
                      onChange={(e) => handleChange(index, e)}
                      type="number"
                      placeholder="1000"
                    ></Input>
                  </div>
                  <div
                    className="mt-8 cursor-pointer stroke-subtext hover:stroke-rose-800"
                    onClick={() =>
                      setFormData(formData.filter((_, i) => i !== index))
                    }
                  >
                    <Cross1Icon />
                  </div>
                </div>
              ))}
              <Button
                variant="outline"
                onClick={() =>
                  setFormData([
                    ...formData,
                    { url: "", handle: "", allocation: 0 },
                  ])
                }
                className="m-auto"
              >
                +
              </Button>
              <p className="text-center text-sm text-red">{finalError}</p>
              <Button
                className="m-auto"
                disabled={
                  !!finalError ||
                  formData.some(
                    (alloc) => alloc.invalidUrl || alloc.allocation === 0
                  )
                }
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </DialogContent>
          </Dialog>
        )}
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead></TableHead>
            <TableHead>Profile</TableHead>
            <TableHead className="text-right">Allocated</TableHead>
            <TableHead className="text-right">Claimed</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data &&
            data.map((kol, i) => (
              <TableRow key={kol.kolId} className="border-0">
                <TableCell className="text-right">{i + 1}.</TableCell>
                <TableCell className="font-medium">
                  {kol.kolAddress ? (
                    <Link to={`/devs/${kol.kolAddress}`}>
                      {kol.username ||
                        displayAddress(kol.kolAddress) ||
                        kol.kolId}
                    </Link>
                  ) : (
                    <a href={reverseHandle(kol.kolId) || ""} target="_blank">
                      {kol.kolId}
                    </a>
                  )}
                </TableCell>
                <TableCell className="text-right">
                  <FormatTokenAmount token={kol.tokenAmount} />
                </TableCell>
                <TableCell className="text-right">
                  <FormatTokenAmount token={kol.claimedAmount} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default KOLAllocation;
