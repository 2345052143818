import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import useWallet from "@/hooks/useWallet";
import { stonkClient } from "@/Utils/stonkClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FaFaucet } from "react-icons/fa";
import { useToast } from "./ui/use-toast";

export function Faucet() {
  const wallet = useWallet();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const { data: message, mutate: claimFaucet } = useMutation({
    mutationFn: async () => {
      const res = await stonkClient.post("/faucet/", undefined, {
        params: {
          user_address: wallet?.address,
        },
      });
      return res.data.data as string;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["balance"] });
      toast({
        title: "Claimed from faucet",
        description: data,
      });
    },
    onError: (error) => {
      toast({
        title: "Error claiming from faucet",
        description: "Something went wrong",
      });
    },
  });

  const handleClaim = () => {
    toast({
      title: "Claiming from faucet...",
      description: "Please wait while we claim from the faucet...",
    });
    claimFaucet();
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          Faucet
          <FaFaucet className="ml-1 h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Claim Testnet ETH</DialogTitle>
          <DialogDescription>
            Click the button below to claim upto 0.05 Testnet ETH and 10K
            Testnet USDC from the faucet.
            <br />
            <span className="text-xs text-gray-500">
              (Note: This is for testing purposes only)
            </span>
          </DialogDescription>
        </DialogHeader>
        <div className="mt-4 flex justify-center">
          <Button onClick={handleClaim}>Claim</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
