/* eslint-disable @typescript-eslint/no-explicit-any  */
import { TokenData } from "@/types/BaseTypes";
import { getOslonTimezone } from "@/Utils/displayDateTime";
import { stonkClient } from "@/Utils/stonkClient";
import { useEffect, useMemo, useRef, useState } from "react";
import { OHLCBlock, ResolutionString, SymbolInfo } from "./MarketTypes";

import {
  IBasicDataFeed,
  SeriesFormat,
  ThemeName,
  Timezone,
  widget, // @ts-expect-error non-typescript library
} from "/public/static/charting_library";

type OHLCType = {
  c: number;
  h: number;
  l: number;
  o: number;
  t: number;
  v: number;
};

const PRICE_PROVIDER = "Product.fun";

const supported_resolutions: ResolutionString[] = [
  // '1S',
  // '10S',
  "1",
  // '3',
  "5",
  // '10',
  "15",
  "30",
  "60",
  // "2H",
  // "4H",
  // "1D",
];

const defaults = {
  priceProvider: "Product.fun",
  // cssPath: "/tv.css",
  upRectangeColor: "rgba(55, 114, 255, 0.04)",
  upLineColor: "rgba(55, 114, 255, 1)",
  downRectangeColor: "rgba(255, 104, 104, 0.04)",
  downLineColor: "rgba(255, 104, 104, 1)",

  library_path: "/static/charting_library/",
  theme: "Dark",
  interval: "1" as ResolutionString,
  basicDisabled: [
    "go_to_date",
    "display_market_status",
    "save_chart_properties_to_local_storage",
    "use_localstorage_for_settings",
    "header_symbol_search",
    "symbol_search_hot_key",
    "header_quick_search",
    "symbol_info",
  ],
  configs: {
    supported_resolutions,
    exchanges: [
      {
        // `exchange` argument for the `searchSymbols` method, if a user selects this exchange
        value: PRICE_PROVIDER,

        // filter name
        name: PRICE_PROVIDER,

        // full exchange name displayed in the filter popup
        desc: PRICE_PROVIDER,
      },
    ],
    symbols_types: [
      {
        name: "Coin",

        // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
        value: "Coin",
      },
    ],
  },
  BG: "#",
  upIcon: "▲",
  downIcon: "▼",
  green: "rgb(108, 211, 173)",
  red: "rgb(255, 104, 104)",
};
const inputOHLCToRawOHLC = (inputOHLC: OHLCType[]) => {
  return inputOHLC.map((element) => ({
    time: element.t * 1000,
    open: element.o / 1e18,
    close: element.c / 1e18,
    high: element.h / 1e18,
    low: element.l / 1e18,
    volume: element.v / 1e18,
  }));
};

type Props = {
  token: TokenData;
  tradingViewRef: any;
};

const TradingView = ({ token, tradingViewRef }: Props) => {
  const realTimeUpdateRef = useRef<{
    symbolInfo: SymbolInfo;
    resolution: ResolutionString;
    onRealtimeCallback: (data: OHLCBlock) => void;
    onResetCacheNeededCallback: () => void;
  } | null>(null);
  const lastSyncedKlineRef = useRef<OHLCBlock | null>(null);
  const [chartReady, setChartReady] = useState<boolean>(false); // eslint-disable-line
  const syncTV = async () => {
    if (typeof realTimeUpdateRef.current?.onRealtimeCallback != "function")
      return;

    const prevBar = lastSyncedKlineRef?.current;
    if (!prevBar) return;

    const _newBars = await getChartData({
      tokenId: token.id,
      from: prevBar.time / 1000,
      resolution: realTimeUpdateRef.current.resolution,
    });
    const newBars = inputOHLCToRawOHLC(_newBars).reverse();
    for (const bar of newBars) {
      realTimeUpdateRef.current.onRealtimeCallback(bar);
    }
    if (newBars.length) {
      lastSyncedKlineRef.current = newBars[newBars.length - 1];
    }
  };

  useEffect(() => {
    if (import.meta.env.VITE_DISABLE_POOLING) return;
    const interval = setInterval(syncTV, 1000);

    return () => clearInterval(interval);
  }, []);

  const getAllSymbols = () => {
    return [
      {
        symbol: token.ticker + "/USD",
        full_name: token.name,
        description: token.description,
        exchange: PRICE_PROVIDER,
        type: "Coin",
        pricescale: 10000000000,
        pair: `${token.ticker}-USD`,
      },
    ];
  };

  const datafeed = useMemo<IBasicDataFeed>((): IBasicDataFeed => {
    return {
      onReady: (callback: any) => {
        setTimeout(() => callback(defaults.configs));
      },
      searchSymbols: async (
        userInput: string,
        exchange: string,
        symbolType: string,
        onResultReadyCallback: (arg: any) => void
      ) => {
        const symbols = getAllSymbols();

        const newSymbols = symbols.filter((symbol) => {
          return (
            typeof symbol.symbol === "string" &&
            symbol.symbol.includes(userInput) &&
            symbol.type.toLowerCase() == symbolType.toLowerCase()
          );
        });
        onResultReadyCallback(newSymbols);
      },
      resolveSymbol: async (
        symbolName: string,
        onSymbolResolvedCallback: (arg: any) => void,
        onResolveErrorCallback: (arg: any) => void
      ) => {
        const parsedSymbol = symbolName.replace("-", "");
        const symbols = getAllSymbols();
        const symbolItem = symbols?.find(
          ({ symbol, full_name }) =>
            symbol === parsedSymbol || full_name === parsedSymbol
        );

        if (!symbolItem) {
          setTimeout(() => onResolveErrorCallback("cannot resolve symbol"), 0);
          return;
        }

        const symbolInfo: SymbolInfo = {
          ticker: token.ticker + "/USD",
          name: token.name,
          description: "",
          type: "Coin",
          session: "24x7",
          full_name: token.name,
          exchange: PRICE_PROVIDER,
          minmov: 1,
          pricescale: 10000000000,
          has_intraday: true,
          has_seconds: true,
          visible_plots_set: "ohlc",
          // has_no_volume: true,
          seconds_multipliers: ["1", "10"],
          has_weekly_and_monthly: true,
          supported_resolutions,
          volume_precision: 2,
          data_status: "streaming",
          timezone: getOslonTimezone() as Timezone,
          listed_exchange: PRICE_PROVIDER,
          format: "price" as SeriesFormat,
        };
        setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
      },
      getBars: async (
        symbolInfo: SymbolInfo,
        resolution: ResolutionString,
        periodParams: { from: number; to: number; firstDataRequest: boolean },
        onHistoryCallback: (...args: any) => void,
        onErrorCallback: (args: any) => void
      ) => {
        try {
          const { from, to, firstDataRequest } = periodParams;
          if (firstDataRequest) {
            lastSyncedKlineRef.current = null;
          }
          const inputOHLC = await getChartData({
            tokenId: token.id,
            from,
            to,
            resolution,
          });
          const ohlc = inputOHLCToRawOHLC(inputOHLC).reverse();
          if (firstDataRequest && ohlc.length) {
            lastSyncedKlineRef.current = ohlc[ohlc.length - 1];
          }
          onHistoryCallback(ohlc, {
            noData: ohlc.length === 0 ? true : false,
          });
        } catch (error) {
          console.log("Error while getting chart data", error);
          onErrorCallback(error as string);
        }
      },
      subscribeBars: (
        symbolInfo: SymbolInfo,
        resolution: ResolutionString,
        onRealtimeCallback: (data: OHLCBlock) => void,
        _: any,
        onResetCacheNeededCallback: () => void
      ) => {
        realTimeUpdateRef.current = {
          symbolInfo,
          resolution,
          onRealtimeCallback,
          onResetCacheNeededCallback,
        };
      },
      unsubscribeBars: () => console.log,
    } as IBasicDataFeed;
  }, [token.id]);
  const containerDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    try {
      const chart = new widget({
        datafeed,
        interval: defaults.interval,
        timeframe: "200",
        locale: "en",
        container: containerDivRef.current!,
        library_path: defaults.library_path,
        // custom_css_url: defaults.cssPath,
        timezone: getOslonTimezone() as Timezone,
        symbol: token.ticker + "/USD",
        overrides: {
          "paneProperties.background": "rgb(0,0,0)",
          // ""
          // "paneProperties.backgroundType": "solid",
        },
        toolbar_bg: "rgb(0,0,0)",

        theme: "dark" as ThemeName,
        loading_screen: {
          backgroundColor: "transparent",
        },
        // load_last_chart: true,
        time_frames: [
          {
            text: "1D",
            resolution: "30" as ResolutionString,
            description: "1 Day look back",
            title: "1D",
          },
          {
            text: "4H",
            resolution: "5" as ResolutionString,
            description: "4 Hour look back",
            title: "4H",
          },
          {
            text: "1H",
            resolution: "1" as ResolutionString,
            description: "1 Hour look back",
            title: "1H",
          },
          {
            text: "30",
            resolution: "1" as ResolutionString,
            description: "30 Minute look back",
            title: "30Min",
          },
          {
            text: "10",
            resolution: "1S" as ResolutionString,
            description: "10 Minute look back",
            title: "10Min",
          },
        ],
        disabled_features:
          window.innerWidth < 600
            ? ["left_toolbar", ...defaults.basicDisabled]
            : [...defaults.basicDisabled],
      });

      chart.onChartReady(() => {
        // chart.activeChart().get;
        // let packedPrice: { pric  e: null | number } = { price: null };
        // chart.activeChart?.().executeActionById('drawingToolbarAction');
        // chart
        //   .activeChart?.()
        //   .crossHairMoved()
        //   .subscribe(null, (p) => {
        //     packedPrice = p;
        //   });
        // document.getElementById(chart._id).contentWindow.document.body.onclick =
        //   () => {
        //     setActiveTab('Limit');
        //     setStrike(round(packedPrice.price, 2));
        //   };
        setChartReady(true);
      });
      tradingViewRef.current = chart;
    } catch (e) {
      console.log("[chart-deb]-err", e);
    }

    return () => {
      // tradingViewRef.current?.remove();
      // tradingViewRef.current = null;
      // setChartReady(false);
    };
  }, [token.id]);
  return (
    <div
      ref={containerDivRef}
      id="chart-element-main"
      className="TVChartContainer h-full w-full"
    />
  );
};

const getChartData = async ({
  tokenId,
  from,
  to,
  resolution,
}: {
  tokenId: `0x${string}`;
  from: number;
  to?: number;
  resolution: ResolutionString;
}) => {
  const res = await stonkClient.get(`token/${tokenId}/chart/`, {
    params: {
      timestamp_from: from,
      timestamp_to: to,
      resolution,
    },
  });
  return res.data.data as OHLCType[];
};

export default TradingView;
