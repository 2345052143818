import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import usePreferences from "@/hooks/usePreferences";
import { defaultPreferences } from "@/state/preferences";
import { useEffect } from "react";
import { FiFilter } from "react-icons/fi";
import { useLocation } from "react-router-dom";

type Props = {
  currentTab: "coins" | "people";
};
const Filters = ({ currentTab }: Props) => {
  const location = useLocation();

  const { preferences, updatePreferences } = usePreferences();

  useEffect(() => {
    if (
      [
        "BumpOrder",
        "LastTrade",
        "LastReply",
        "MarketCap",
        "ReplyCount",
        "CreationTime",
      ].includes(preferences.tokenFilters.sortBy) === false
    ) {
      updatePreferences({
        tokenFilters: {
          ...preferences.tokenFilters,
          sortBy: "BumpOrder",
          order: "desc",
        },
      });
    }
    if (
      ['"score"', '"followers"', '"tokens_created"', '"token_held"'].includes(
        preferences.peopleFilters.sortBy
      ) === false
    ) {
      updatePreferences({
        peopleFilters: {
          ...preferences.peopleFilters,
          sortBy: '"score"',
          order: "desc",
        },
      });
    }
  }, [preferences, updatePreferences]);

  const isApplied =
    currentTab === "coins"
      ? matchFiltersApplied(
          defaultPreferences.tokenFilters,
          preferences.tokenFilters
        )
      : matchFiltersApplied(
          defaultPreferences.peopleFilters,
          preferences.peopleFilters
        );

  if (currentTab !== "coins" && currentTab !== "people") {
    return <></>;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className="relative cursor-pointer rounded-sm p-1">
          <FiFilter className="text-xl" />
          {isApplied && (
            <div className="absolute bottom-2 right-0.5 h-1.5 w-1.5 rounded-full bg-red"></div>
          )}
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-fit">
        <div className="flex w-full flex-col gap-1 text-sm">
          {currentTab === "people" && (
            <>
              <div className="flex flex-1 items-center gap-2">
                <p className="text-nowrap text-sm">Sort By</p>
                <Select
                  value={preferences.peopleFilters.sortBy}
                  onValueChange={(value) =>
                    updatePreferences({
                      peopleFilters: {
                        ...preferences.peopleFilters,
                        sortBy: value,
                      },
                    })
                  }
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Sort" />
                  </SelectTrigger>
                  <SelectContent className="text-sm">
                    <SelectItem value='"score"'>Score</SelectItem>
                    <SelectItem value='"followers"'>Followers</SelectItem>
                    <SelectItem value='"tokens_created"'>
                      Coins Created
                    </SelectItem>
                    <SelectItem value='"token_held"'>Coins Held</SelectItem>
                  </SelectContent>
                </Select>
                <p className="text-sm">Order</p>
                <Select
                  value={preferences.peopleFilters.order}
                  onValueChange={(value) =>
                    updatePreferences({
                      peopleFilters: {
                        ...preferences.peopleFilters,
                        order: value,
                      },
                    })
                  }
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Order" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="asc">Asc</SelectItem>
                    <SelectItem value="desc">Desc</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex flex-wrap justify-between gap-2">
                <div
                  className="flex cursor-pointer items-center"
                  onClick={() =>
                    updatePreferences({
                      peopleFilters: {
                        ...preferences.peopleFilters,
                        onlyFollowing: !preferences.peopleFilters.onlyFollowing,
                      },
                    })
                  }
                >
                  <span>Whom you follow</span>
                  <Switch
                    className="ml-2"
                    checked={preferences.peopleFilters.onlyFollowing}
                  />
                </div>
                <div
                  className="flex cursor-pointer items-center"
                  onClick={() =>
                    updatePreferences({
                      hideNsfw: !preferences.hideNsfw,
                    })
                  }
                >
                  <span>Show NSFW</span>
                  <Switch className="ml-2" checked={!preferences.hideNsfw} />
                </div>
              </div>
            </>
          )}{" "}
          {currentTab === "coins" && (
            <>
              <div className="flex flex-1 items-center gap-2">
                <p className="text-nowrap text-sm">Sort By</p>
                <Select
                  value={preferences.tokenFilters.sortBy}
                  onValueChange={(value) =>
                    updatePreferences({
                      tokenFilters: {
                        ...preferences.tokenFilters,
                        sortBy: value,
                      },
                    })
                  }
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Sort" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="BumpOrder">Bump Order</SelectItem>
                    <SelectItem value="LastTrade">Last Trade</SelectItem>
                    <SelectItem value="LastReply">Last Reply</SelectItem>
                    <SelectItem value="MarketCap">Market Cap</SelectItem>
                    <SelectItem value="ReplyCount">Reply Count</SelectItem>
                    <SelectItem value="CreationTime">Creation Time</SelectItem>
                  </SelectContent>
                </Select>
                <p className="text-sm">Order</p>
                <Select
                  value={preferences.tokenFilters.order}
                  onValueChange={(value) =>
                    updatePreferences({
                      tokenFilters: {
                        ...preferences.tokenFilters,
                        order: value,
                      },
                    })
                  }
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Order" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="asc">Asc</SelectItem>
                    <SelectItem value="desc">Desc</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex flex-wrap justify-between gap-2">
                <div
                  className="flex cursor-pointer"
                  onClick={() =>
                    updatePreferences({
                      tokenFilters: {
                        ...preferences.tokenFilters,
                        onlyFollowing: !preferences.tokenFilters.onlyFollowing,
                      },
                    })
                  }
                >
                  <p>Filter by following</p>
                  <Switch
                    className="ml-2"
                    checked={preferences.tokenFilters.onlyFollowing}
                  />
                </div>
                <div
                  className="flex cursor-pointer items-center"
                  onClick={() =>
                    updatePreferences({
                      hideNsfw: !preferences.hideNsfw,
                    })
                  }
                >
                  <span>Show NSFW</span>
                  <Switch className="ml-2" checked={!preferences.hideNsfw} />
                </div>
              </div>
            </>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default Filters;

const matchFiltersApplied = (
  preferences: Record<string, unknown>,
  filters: Record<string, unknown>
) => {
  for (const [key, value] of Object.entries(preferences)) {
    if (filters[key] !== value) {
      return true;
    }
  }
  return false;
};
