import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const FormatEthAmount = ({ eth }: { eth?: number | bigint | null }) => {
  if (!eth) return <span>0</span>;
  if (typeof eth === "bigint") eth = +eth.toString();
  eth = eth / 1e18;
  return (
    <span>
      {eth < 0.00001 ? (
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger>{`<0.00001`}</TooltipTrigger>
            <TooltipContent>
              <p>{eth.toFixed(18).replace(/\.0$/, "")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <span>{formatNumber(eth)}</span>
      )}
    </span>
  );
};

const FormatTokenAmount = ({
  token,
  decimals = 8,
}: {
  token?: number | bigint | null;
  decimals?: number;
}) => {
  if (!token) return <span>0</span>;
  if (typeof token === "bigint") token = +token.toString();
  token = token / 10 ** decimals;
  return (
    <span>
      {token < 0.0001 ? (
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger>{`<0.0001`}</TooltipTrigger>
            <TooltipContent>
              <p>{token.toFixed(decimals).replace(/\.0$/, "")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <span>{formatNumber(token)}</span>
      )}
    </span>
  );
};

export { FormatEthAmount, FormatTokenAmount };
/**
 *
 * if amount < 0.0001, display "< 0.0001" with actual amount in a tooltip"
 */

const formatNumber = (num: number | bigint) => {
  if (typeof num === "bigint") num = Number(num);
  if (num < 1000) {
    return num.toFixed(4).replace(/\.0000$/, "");
  } else if (num < 1_000_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "k";
  } else if (num < 1_000_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else {
    return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
  }
};
