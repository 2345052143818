import { PrivyClientConfig } from "@privy-io/react-auth";
import { createConfig } from "@privy-io/wagmi";
import { QueryClient } from "@tanstack/react-query";
import { defineChain } from "viem";
import { http } from "wagmi";

console.log("import.meta.env.VITE_RPC_URL", import.meta.env.VITE_RPC_URL);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 1000,
    },
  },
});

const customAnvil = defineChain({
  id: +import.meta.env.VITE_CHAIN_ID,
  name: "Anvil",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: [import.meta.env.VITE_RPC_URL],
      // webSocket: [import.meta.env.VITE_RPC_WS_URL],
    },
  },
});

export const defaultChain = customAnvil;

export const privyConfig = {
  // Customize Privy's appearance in your app
  appearance: {
    theme: "dark",
    accentColor: "#676FFF",
    logo: "/logo.png",
  },
  // Create embedded wallets for users who don't have a wallet
  supportedChains: [defaultChain],
  defaultChain: defaultChain,
  embeddedWallets: {
    createOnLogin: "users-without-wallets",
  },
} as PrivyClientConfig;

export const wagmiConfig = createConfig({
  chains: [defaultChain],
  // connectors,
  transports: {
    [defaultChain.id]: http(),
  },
});
