import Info from "@/assets/InfoIcon";
import ultrastonkTitle from "@/assets/ultraStonk.png";
import { MemeCard } from "@/components/MemeCard";
import Filters from "@/components/RightSection/Filters";
import Search from "@/components/RightSection/Search";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import usePreferences from "@/hooks/usePreferences";
import useWallet from "@/hooks/useWallet";
import { cn } from "@/lib/utils";
import { TokenData } from "@/types/BaseTypes";
import { stonkClient } from "@/Utils/stonkClient";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  ultraStonk?: TokenData;
  isMobile: boolean;
};
const CoinsTab = ({ ultraStonk, isMobile }: Props) => {
  const { preferences } = usePreferences();
  const wallet = useWallet();
  const location = useLocation();
  const navigate = useNavigate();

  const [, , selectedCoinAddress] = location.pathname.split("/");

  const { data, loadMoreRef, isLoading, isFetchingNextPage } =
    useInfiniteScroll({
      queryKey: ["tokens", JSON.stringify(preferences.tokenFilters)],
      queryFn: async ({ pageParam }) => {
        const filters = [];
        if (preferences.tokenFilters.onlyFollowing) filters.push("followed");
        const res = await stonkClient(`token/`, {
          params: {
            user_address: wallet?.address || undefined,
            sort_by: preferences.tokenFilters.sortBy,
            order_by: preferences.tokenFilters.order,
            filter_by: filters,
            page: pageParam,
          },
        });
        return res.data.data as TokenData[];
      },
    });

  useEffect(() => {
    if (selectedCoinAddress !== "top") return;
    if (isMobile) {
      navigate("/apps", { replace: true });
      return;
    }
    if (ultraStonk) {
      navigate(`/apps/${ultraStonk.id}`, { replace: true });
    } else if (data?.pages[0]?.length) {
      navigate(`/apps/${data.pages[0][0].id}`, { replace: true });
    }
  }, [ultraStonk, data, selectedCoinAddress]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        <Search type="app" />
        <Filters currentTab="coins" />
      </div>
      {!!ultraStonk && (
        <div
          className={cn(
            "relative z-10 rounded-md border border-[#EF18F9] p-px shadow-[0_0_10px_2px_#EF18F9]",
            {
              "border-2 p-0": selectedCoinAddress === ultraStonk.id,
              "hover:border-2 hover:p-0": selectedCoinAddress !== ultraStonk.id,
            }
          )}
        >
          <div className="-mb-3 flex">
            <img
              src={ultrastonkTitle}
              alt="ultra stonk"
              className="w-2/3 max-w-[250px]"
            />
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger>
                  <Info className="ml-1 fill-accent" />
                </TooltipTrigger>
                <TooltipContent side="top">
                  <p className="max-w-64">
                    Latest coin to reach Market cap of 5 ETH
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <MemeCard token={ultraStonk} noOutline />
        </div>
      )}
      <div className="flex flex-col gap-2">
        <div className="flex flex-col">
          {!!data &&
            data.pages.map((group, i) => (
              <React.Fragment key={i}>
                {group.map((item) =>
                  item.id !== ultraStonk?.id ? (
                    <div
                      key={item.id}
                      className={cn(
                        "rounded-md border border-transparent hover:border-gray-500",
                        {
                          "border-gray-500": selectedCoinAddress === item.id,
                        }
                      )}
                    >
                      <MemeCard token={item} noOutline />
                    </div>
                  ) : null
                )}
              </React.Fragment>
            ))}
        </div>
        <div ref={loadMoreRef} id="load-more" style={{ height: 1 }} />
        {isFetchingNextPage && <p>Loading more...</p>}
        {isLoading && !isFetchingNextPage && <p>Loading...</p>}
        {!!data && (data.pages.length === 0 || data?.pages[0].length === 0) && (
          <p>Nothing here yet</p>
        )}
      </div>
    </div>
  );
};

export default CoinsTab;
