import { stonkClient } from "@/Utils/stonkClient";
import TradingView from "@/components/TradingView/TradingView";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";
import Chat from "./Chat";

import { lpLockerAbi } from "@/ABIs/Router";
import { Contracts } from "@/ContractsConfig";
import { Button } from "@/components/ui/button";
import useGenericContract from "@/hooks/useGenericContract";
import useWallet from "@/hooks/useWallet";
import { TokenData } from "@/types/BaseTypes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { IoImages } from "react-icons/io5";
import { BuySellTab } from "./BuySellTab";
import Holders from "./Holders";
import InfoMain from "./InfoMain";
import KOLAllocation from "./KOLAllocation";
import { Replies } from "./ReplyThread";
import Trades from "./Trades";

type Props = {
  tokenId: `0x${string}`;
};
const TokenPage = ({ tokenId }: Props) => {
  // const { tokenId = "0x" } = useParams<{ tokenId: `0x${string}` }>();

  const tradingViewRef = useRef<any | null>(null); // eslint-disable-line
  const [tradingViewKey, setTradingViewKey] = useState(0);
  const [tabValue, setTabValue] = useState("preview");
  const newToken = useRef(false);

  const tokenData = useQuery({
    queryKey: ["token", tokenId],
    queryFn: async () => {
      const res = await stonkClient(`token/${tokenId}/`);
      const data = res.data.data;
      data.preview_images = JSON.parse(data.preview_images || "[]");
      return data as TokenData;
    },
    refetchInterval: import.meta.env.VITE_DISABLE_POOLING ? undefined : 1000,
    enabled: !!tokenId?.startsWith("0x"),
  });

  useEffect(() => {
    if (tokenData.data && tokenData.data.soldInBondingCurve === 0) {
      newToken.current = true;
    }
    if (tokenData.data && tokenData.data.preview_images?.length === 0) {
      setTabValue("chart");
    }
  }, [tokenData.data]);

  const onBuy = () => {
    // Reload trading view on first buy
    if (newToken.current) {
      // This trading view api does not working (according to docs it should work)
      // tradingViewRef.current?.activeChart().resetData();
      // So using this hack to rerender the whole trading view component
      setTradingViewKey((prev) => prev + 1);
    }
  };

  if (tokenData.isLoading) return <div>Loading...</div>;
  if (tokenData.isError) return <div>Error:{tokenData.error.message}</div>;
  if (!tokenData.data) return <div>No data found</div>;
  return (
    <div className="mb-10 flex w-full flex-col gap-2" key={tokenId}>
      {/* Nav bar */}
      {/* <div className="hidden items-center gap-4 sm:flex">
        <Link to="/">
          <img src="/Logo.png" className="h-10 w-10"></img>
        </Link>
        <span
          className="cursor-pointer text-md text-secondary-foreground"
          onClick={openHowItWorks}
        >
          [How it works]
        </span>
        {tokenData.data.soldInBondingCurve >=
          tokenData.data.graduationTokenAmount && (
          <GraduatedDialog tokenAddress={tokenId} />
        )}
        <div className="ml-auto">
          <ConnectButton accountStatus={"address"} />
        </div>
      </div> */}
      {/* Title Section */}
      {/* <div className="flex gap-2">
        <h1 className="text-lg">
          {tokenData.data.name}{" "}
          <span className="text-secondary-foreground">
            ${tokenData.data.ticker}{" "}
            {wallet?.address === tokenData.data.creator_address && (
              <SettingsDialog
                tokenId={tokenId}
                settings={{
                  twitter: tokenData.data.twitter,
                  telegram: tokenData.data.telegram,
                  website: tokenData.data.website,
                  chat_access_balance: tokenData.data.chat_access_balance,
                }}
              />
            )}
          </span>
        </h1>
      </div> */}
      {/* <div className="flex flex-wrap gap-x-4 gap-y-1 text-sm">
        <span className="text-secondary-foreground">{tokenData.data.name}</span>
        <span className="text-secondary-foreground">
          Ticker: {tokenData.data.ticker}
        </span>
        <CopyTooltip
          textToCopy={tokenData.data.id}
          tooltipText="Copy"
          side="bottom"
        >
          <p className="cursor-pointer text-secondary-foreground">
            {displayAddress(tokenData.data.id)}
          </p>
        </CopyTooltip>
        <span className="text-accent-green">
          Price: <FormatEthAmount eth={tokenData.data.pricePerToken} />Ξ (
          {compactUSD((tokenData.data.pricePerToken || 0) / 1e18)})
        </span>
        <span className="text-accent-green">
          Mcap: {compactUSD((tokenData.data.marketCap || 0) / 1e18)}
        </span>
        <span className="text-secondary-foreground">
          Created By:{" "}
          <Link
            to={`/devs/${tokenData.data.creator_address}`}
            className="text-link"
          >
            @{tokenData.data.creator_username}
          </Link>
        </span>
      </div> */}
      {/* <div className="h-96 xl:hidden">
        <TradingView
          token={tokenData.data}
          tradingViewRef={tradingViewRef}
          key={tokenId + tradingViewKey}
        />
      </div> */}
      <div className="flex w-full gap-4">
        {/* Main section (left) */}
        <div className="flex w-full min-w-0 flex-auto flex-col gap-4 lg:w-2/3 xl:w-3/4">
          <InfoMain token={tokenData.data} />
          <Tabs
            defaultValue="preview"
            value={tabValue}
            onValueChange={setTabValue}
            className=""
          >
            <TabsList>
              <TabsTrigger value="preview">Preview</TabsTrigger>
              <TabsTrigger value="chart">Chart</TabsTrigger>
            </TabsList>
            <div className="pt-3">
              <TabsContent value="preview">
                <div className="scroll-hidden flex h-80 gap-4 overflow-x-auto">
                  {!tokenData.data.preview_images?.length && (
                    <div className="flex h-full w-full flex-col items-center justify-center text-subtext">
                      <IoImages className="h-40 w-40" />
                      <p className="">No images</p>
                    </div>
                  )}
                  {tokenData.data.preview_images?.map((image, i) => (
                    <img src={image} key={image + i} className="h-full" />
                  ))}
                </div>
              </TabsContent>
              <TabsContent value="chart">
                <div className="flex h-80 gap-4">
                  <TradingView
                    token={tokenData.data}
                    tradingViewRef={tradingViewRef}
                    key={tradingViewKey}
                  />
                </div>
              </TabsContent>
            </div>
          </Tabs>
          <div className="block lg:hidden">
            <RightSection tokenData={tokenData.data} onBuy={onBuy} />
          </div>
          <Tabs defaultValue="replies" className="block w-full">
            <TabsList>
              <TabsTrigger value="replies">Replies</TabsTrigger>
              <TabsTrigger value="trades">Trades</TabsTrigger>
              <TabsTrigger value="holders">Holders</TabsTrigger>
              <TabsTrigger value="kol">Reserve Allocation</TabsTrigger>
            </TabsList>
            <div className="pt-3">
              <TabsContent value="replies">
                <Replies tokenAddress={tokenId} />
              </TabsContent>
              <TabsContent value="trades">
                <Trades
                  tokenAddress={tokenId}
                  tokenTicker={tokenData.data.ticker}
                />
              </TabsContent>
              <TabsContent value="holders">
                <Holders
                  tokenAddress={tokenData.data.id}
                  tokenCreated={tokenData.data.created}
                />
              </TabsContent>
              <TabsContent value="kol">
                <KOLAllocation
                  tokenAddress={tokenData.data.id}
                  tokenData={tokenData.data}
                />
              </TabsContent>
            </div>
          </Tabs>
        </div>
        {/* Right Section */}
        <div className="hidden w-full max-w-md flex-col gap-3 lg:flex lg:w-2/5 xl:w-1/3 xl:pl-4">
          {/* <div className="flex rounded-lg bg-b1 py-1 lg:hidden">
            <Sheet>
              <SheetTrigger asChild>
                <button className="w-full border-r border-subtext py-1">
                  Replies
                </button>
              </SheetTrigger>
              <SheetContent
                side="bottom"
                className="flex h-[80%] flex-col px-0"
              >
                <SheetTitle className="px-4">Replies</SheetTitle>
                <div className="overflow-auto">
                  <Replies tokenAddress={tokenId} />
                </div>
              </SheetContent>
            </Sheet>
            <Sheet>
              <SheetTrigger asChild>
                <button className="w-full border-r border-subtext py-1">
                  Chat
                </button>
              </SheetTrigger>
              <SheetContent
                side="bottom"
                className="flex h-[80%] flex-col px-4"
              >
                <SheetTitle>Holders Chat</SheetTitle>
                <div className="flex-auto overflow-y-auto">
                  <Chat
                    tokenAddress={tokenId}
                    ticker={tokenData.data.ticker}
                    chat_access_balance={tokenData.data.chat_access_balance}
                  />
                </div>
              </SheetContent>
            </Sheet>
            <Sheet>
              <SheetTrigger asChild>
                <button className="w-full py-1">Trades</button>
              </SheetTrigger>
              <SheetContent
                side="bottom"
                className="flex h-[80%] flex-col px-4"
              >
                <SheetTitle>Trades</SheetTitle>
                <div className="overflow-y-auto">
                  <Trades
                    tokenAddress={tokenId}
                    tokenTicker={tokenData.data.ticker}
                  />
                </div>
              </SheetContent>
            </Sheet>
          </div> */}
          {/* <div className="ml-auto"></div> */}
          <div className="hidden lg:block">
            <RightSection tokenData={tokenData.data} onBuy={onBuy} />
          </div>
          {/* <ClaimRewards tokenAddress={tokenId} /> */}

          {/* <Info tokenData={tokenData.data} /> */}
        </div>
      </div>
    </div>
  );
};

const RightSection = ({
  tokenData,
  onBuy,
}: {
  tokenData: TokenData;
  onBuy: () => void;
}) => {
  const wallet = useWallet();
  const { callContract: callCollectFee } = useGenericContract({
    toastTitle: "Collecting fee",
  });

  return (
    <>
      <div className="mx-auto w-full max-w-lg">
        <BuySellTab tokenData={tokenData} onBuy={onBuy} />
      </div>
      {!tokenData.hasGraduated ? (
        <p className="text-md font-semibold text-secondary-foreground">
          On reaching graduation, the last buyer will get $50, and the creator
          will get $50.
        </p>
      ) : (
        tokenData.creator_address === wallet?.address && (
          <div className="my-4 flex items-center gap-2">
            <span>Creator Fee</span>
            <Button
              size="md"
              onClick={() => {
                console.log("Collect fee clicked", tokenData.lpTokenId);
                callCollectFee({
                  abi: lpLockerAbi,
                  functionName: "collectFees",
                  args: [BigInt(tokenData.lpTokenId || 0)],
                  address: Contracts.lpLocker,
                });
              }}
            >
              Collect Fee
            </Button>
          </div>
        )
      )}
      <div className="mt-4 h-96">
        <p className="text-md font-semibold text-secondary-foreground">
          Members only chat
        </p>
        <Chat
          tokenAddress={tokenData.id}
          ticker={tokenData.ticker}
          chat_access_balance={tokenData.chat_access_balance}
        />
      </div>
    </>
  );
};

export default TokenPage;
