import { CoinCreateSchema } from "@/Schemas/CoinCreateSchema";
import { uploadImageFromForm } from "@/Utils/ImageUploadHelpers";
import { compactFormatter } from "@/Utils/NumberHandling/compactFormatter";
import {
  BondingCurveRates,
  bondingCurveGrowthRate,
  getTokenomics,
} from "@/Utils/PlatformMath/getTokenomics";
import { decodeCreateTokenEvent } from "@/Utils/decodeLogs";
import { stonkClient } from "@/Utils/stonkClient";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { HandeledInput, HandeledTextArea, Input } from "@/components/ui/input";
import useContract from "@/hooks/useContract";
import useWallet from "@/hooks/useWallet";
import { cn } from "@/lib/utils";
import { usePrivy } from "@privy-io/react-auth";
import { useQueryClient } from "@tanstack/react-query";
import { Field, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate, useSearchParams } from "react-router-dom";

const curves = [
  { name: "Hodl", image: "/Curves/Hodl.svg" },
  { name: "Moon", image: "/Curves/Moon.svg" },
  { name: "Mars", image: "/Curves/Mars.svg" },
];

// https://viem.sh/docs/contract/decodeEventLog#partial-decode

const totalSupply = 1e9;

type FormType = {
  name: string;
  ticker: string;
  description: string;
  short_description: string;
  twitter: string;
  telegram: string;
  website: string;
  // other: string;
  image: null | File | string;
  curveType: keyof BondingCurveRates;
  graduation: number;
  tokensToBuy: number;
  buy: boolean;
};

const CreateAToken = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const wallet = useWallet();
  const { login } = usePrivy();

  const [buyTokenModal, setBuyTokenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams({
    name: "",
    ticker: "",
    description: "",
    image: "",
  });

  const { callContract, isConfirmed, data, toast } = useContract({
    toastTitle: "Creating Coin",
    onConfirmDelayed: () => {
      queryClient.invalidateQueries();
    },
  });

  useEffect(() => {
    if (isConfirmed && data) {
      const createTokenEvent = decodeCreateTokenEvent(data.logs);
      console.log(`CreateToken-decodedData: `, createTokenEvent);
      const tokenAddress = createTokenEvent?.args.tokenAddress;
      console.log(`CreateToken-tokenAddress: `, tokenAddress);
      toast({
        title: "Coin created successfully",
        description: (
          <div className="flex items-baseline">
            Redirecting you to the coin page
          </div>
        ),
      });
      setTimeout(() => {
        navigate(`/apps/${tokenAddress}`);
      }, 2000);
    }
  }, [isConfirmed, data, navigate, toast]);

  const curveToType = {
    HODL: 0,
    MOON: 1,
    MARS: 2,
  };
  const tokenCreationHandler = async (
    hash: `0x${string}`,
    creator: `0x${string}`,
    values: FormType
  ) => {
    const tokenomics = getTokenomics(
      values.graduation,
      values.curveType,
      values.tokensToBuy
    );

    const argsOpt = {
      name: values.name,
      imageDescriptionHash: hash,
      creator: creator,
      graduationTokenAmount:
        BigInt(Math.round(values.graduation)) * BigInt(1e8),
      curveType: curveToType[values.curveType],
      symbol: values.ticker,
    };

    await callContract(
      "createAndBuyToken",
      [argsOpt, BigInt(Math.round(values.tokensToBuy * 1e8)), ""],
      BigInt(
        Math.ceil(
          tokenomics.buyAmountPriceWei + tokenomics.creationFee * 1e18 + 1
        )
      )
    );
    setTimeout(() => {
      console.log("invalidating queries");
      queryClient.invalidateQueries();
    }, 3000);
  };

  return (
    <div className="mb-6 flex-auto">
      <Formik
        enableReinitialize
        initialValues={
          {
            name: searchParams.get("name") || "",
            ticker: searchParams.get("ticker")?.toUpperCase() || "",
            short_description: searchParams.get("short_description") || "",
            description: searchParams.get("description") || "",
            twitter: "",
            telegram: "",
            website: "",
            // other: "",
            image: searchParams.get("image") || null,
            curveType: Object.keys(bondingCurveGrowthRate)[0],
            graduation: 574000000,
            tokensToBuy: 0,
            buy: false,
          } as FormType
        }
        validationSchema={CoinCreateSchema}
        onSubmit={async (v) => {
          console.log("submitting", v);
          if (!wallet || !v.image) return;
          toast({
            title: "Creating Coin",
          });
          setIsLoading(true);
          const url = await uploadImageFromForm(v.image);

          const resp = await stonkClient.post("token/", null, {
            params: {
              user_address: wallet?.address,
              user_signature: await wallet?.getSignature(),
              graduation_token_amount: String(v.graduation) + "00000000",
              curve_type: curveToType[v.curveType],
              image: url,
              name: v.name,
              ticker: v.ticker,
              short_description: v.short_description,
              description: v.description,
              twitter: v.twitter,
              telegram: v.telegram,
              website: v.website,
            },
          });
          const { hash, token_address } = resp.data.data;
          console.log({ hash, token_address });
          queryClient.invalidateQueries({ queryKey: ["tokens"] });
          navigate(`/apps/${token_address}`);
          if (v.buy) {
            tokenCreationHandler(hash, wallet.address, v);
          } else {
            toast({
              title: "Coin created!",
              description: <div className="flex items-baseline"></div>,
            });
          }
          setIsLoading(false);
        }}
      >
        {(props) => {
          const tokenomics = getTokenomics(
            props.values.graduation,
            props.values.curveType,
            props.values.tokensToBuy
          );
          return (
            <div className="flex w-full justify-center">
              <div className="flex w-full max-w-xl flex-col">
                <div className="my-3 self-center text-lg font-semibold text-secondary">
                  Launch an App
                </div>
                <form
                  onSubmit={props.handleSubmit}
                  className="grid grid-cols-2 gap-x-4 gap-y-4"
                >
                  <HandeledInput
                    className="w-full"
                    label="Name"
                    placeholder="Name"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.name}
                    name="name"
                    error={
                      props.errors.name && props.touched.name
                        ? props.errors.name
                        : null
                    }
                  />
                  <HandeledInput
                    className="dollar-after w-full pl-6"
                    // rootClassName={"dollar-after"}
                    label="Ticker"
                    placeholder="Ticker"
                    type="text"
                    name="ticker"
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                      props.handleChange(e);
                    }}
                    onBlur={props.handleBlur}
                    value={props.values.ticker}
                    error={
                      props.errors.ticker && props.touched.ticker
                        ? props.errors.ticker
                        : null
                    }
                  />
                  <HandeledInput
                    className="w-full"
                    rootClassName="col-span-2"
                    label="Short Description"
                    placeholder="Describe the app in one line"
                    type="text"
                    name="short_description"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.short_description}
                    error={
                      props.errors.short_description &&
                      props.touched.short_description
                        ? props.errors.short_description
                        : null
                    }
                  />
                  <Field
                    name="image"
                    type="file"
                    // validate={imageValidate}
                  >
                    {() => {
                      const onDrop = useCallback((acceptedFiles: File[]) => {
                        props.setFieldValue("image", acceptedFiles[0] || null);
                      }, []);
                      const { getRootProps, getInputProps, isDragActive } =
                        useDropzone({
                          onDrop,
                          accept: {
                            "image/*": [
                              ".png",
                              ".jpg",
                              ".jpeg",
                              ".webp",
                              ".gif",
                              ".svg",
                            ],
                          },
                        });

                      return (
                        <div className="col-span-2 w-full">
                          <div className="mb-1">App Logo</div>
                          <label
                            htmlFor="ImageDD"
                            className={cn(
                              "flex min-h-12 w-full cursor-pointer items-center justify-center gap-2 rounded-md border-[2px] border-dashed border-secondary-foreground px-3 py-1 text-secondary",
                              props.errors.image &&
                                props.touched.image &&
                                "border-red"
                            )}
                            {...getRootProps()}
                          >
                            <div
                              className={cn(
                                "flex w-full flex-col items-center justify-center gap-2",
                                !(props.values.image && !props.errors.image) &&
                                  "hidden"
                              )}
                            >
                              <img
                                key={
                                  typeof props.values.image === "string" ||
                                  props.values.image === null
                                    ? props.values.image || "no-image"
                                    : URL.createObjectURL(props.values.image)
                                }
                                src={
                                  typeof props.values.image === "string" ||
                                  props.values.image === null
                                    ? props.values.image || ""
                                    : URL.createObjectURL(props.values.image)
                                }
                                id="image-preview"
                                className="h-32 w-32 rounded-sm"
                              />
                              <p>
                                {isDragActive
                                  ? "Drop image here"
                                  : "Got the image"}
                              </p>
                            </div>
                            <p
                              className={
                                props.values.image && !props.errors.image
                                  ? "hidden"
                                  : ""
                              }
                            >
                              {isDragActive
                                ? "Drop image here"
                                : "Select or drop image here"}
                            </p>
                          </label>
                          <Input
                            id="ImageDD"
                            className="absolute z-[-1] w-fit border-2 opacity-0"
                            placeholder="Image"
                            type="file"
                            name="image"
                            onChange={(e) => {
                              if (e.target.files) {
                                props.setFieldValue(
                                  "image",
                                  e.target.files[0] || null
                                );
                              }
                            }}
                            onBlur={props.handleBlur}
                            {...getInputProps()}
                          />
                          {props.errors.image && props.touched.image ? (
                            <p className="text-sm text-red">
                              {props.errors.image}
                            </p>
                          ) : null}
                        </div>
                      );
                    }}
                  </Field>
                  <HandeledInput
                    rootClassName="col-span-2"
                    className="outline-red-400 w-full"
                    label="App URL"
                    optional
                    placeholder="Website"
                    type="text"
                    name="website"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.website}
                    error={
                      props.errors.website && props.touched.website
                        ? props.errors.website
                        : null
                    }
                  />
                  <HandeledTextArea
                    rootClassName="col-span-2"
                    label="Detailed Description"
                    optional
                    // placeholder="Description"
                    type="text"
                    name="description"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.description}
                    error={
                      props.errors.description && props.touched.description
                        ? props.errors.description
                        : null
                    }
                  />
                  <HandeledInput
                    className="outline-red-400 w-full"
                    label="Socials"
                    optional
                    placeholder="Twitter"
                    type="text"
                    name="twitter"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.twitter}
                    error={
                      props.errors.twitter && props.touched.twitter
                        ? props.errors.twitter
                        : null
                    }
                  />
                  <HandeledInput
                    className="w-full"
                    label="&#8203;"
                    placeholder="Telegram"
                    type="text"
                    name="telegram"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.telegram}
                    error={
                      props.errors.telegram && props.touched.telegram
                        ? props.errors.telegram
                        : null
                    }
                  />

                  {/* <HandeledInput
                  className="w-full"
                  placeholder="Other"
                  name="other"
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.other}
                  error={
                    props.errors.other && props.touched.other
                      ? props.errors.other
                      : null
                  }
                /> */}
                  {/* <div>
                      <p className="mb-2 text-secondary-foreground">
                        {" "}
                        Select price curve
                      </p>
                      <div className="flex w-full gap-[7px]">
                        {curves.map((c) => (
                          <div key={c.name}>
                            <label>
                              <input
                                type="radio"
                                name={c.name}
                                value={c.name}
                                checked={
                                  props.values.curveType == c.name.toUpperCase()
                                }
                                onChange={(ip) => {
                                  props.setFieldValue(
                                    "curveType",
                                    ip.target.value.toUpperCase()
                                  );
                                }}
                              />
                              <img src={c.image} alt="Option 1" />
                              <p
                                className={cn(
                                  "mt-1 text-center text-sm font-medium text-subtext",
                                  props.values.curveType == c.name &&
                                    "text-primary-foreground"
                                )}
                              >
                                {c.name}
                              </p>
                            </label>
                          </div>
                        ))}
                      </div>
                      <p className="mt-2 max-w-[240px] whitespace-pre-wrap text-sm font-medium text-subtext">
                        It will take {compactFormatter(tokenomics.totalCost)}{" "}
                        ETH ({compactUSD(tokenomics.totalCost)}) to sell{" "}
                        {tokenomics.graduationSupplyPercentage.toFixed(3)}%
                        supply, making the graduation market cap{" "}
                        {tokenomics.graduationMC.toFixed(3)} ETH (
                        {compactUSD(tokenomics.graduationMC)}) and listing price{" "}
                        {tokenomics.listingPrice.toFixed(3)} ETH{" "}
                        {compactUSD(tokenomics.listingPrice)}
                      </p>
                    </div> */}
                  {/* <div className="w-full">
                      <div className="flex items-baseline justify-between">
                        <p className="mb-2 text-secondary-foreground">
                          {" "}
                          Sell to Community
                        </p>
                        <Input
                          className="ml-auto mr-1 w-12 p-2 text-right"
                          value={Math.round(props.values.graduation / 1000000)}
                          onChange={(e) =>
                            props.setFieldValue(
                              "graduation",
                              +e.target.value * 1000000
                            )
                          }
                        />
                        <span className="text-md">M</span>
                      </div>
                      <Slider
                        defaultValue={[props.values.graduation]}
                        max={660000000}
                        min={100000000}
                        value={[props.values.graduation]}
                        step={1000000}
                        className="mt-3"
                        onValueChange={(evalval) => {
                          props.setFieldValue("graduation", evalval);
                        }}
                      />
                      <div className="mt-1 flex justify-between font-medium">
                        <button
                          className="text-tiny"
                          onClick={() =>
                            props.setFieldValue("graduation", 100000000)
                          }
                        >
                          Min
                        </button>
                        <button
                          className="text-tiny"
                          onClick={() =>
                            props.setFieldValue("graduation", 660000000)
                          }
                        >
                          Max
                        </button>
                      </div>
                      <p className="text-sm text-red">
                        {props.errors.graduation}
                      </p>
                      <div className="mt-2 text-sm text-subtext">
                        <div className="flex items-center justify-between">
                          <p>Sell via Bonding curve</p>
                          <p className="text-right">
                            {tokenomics.graduationSupplyPercentage.toFixed(2)}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p>Reserve</p>
                          <p className="text-right">
                            {tokenomics.reservePercentage.toFixed(2)}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p>Liquidity</p>
                          <p className="text-right">
                            {tokenomics.liquidityPercentage.toFixed(2)}%
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p>Graduation Market cap</p>
                          <p className="text-right">
                            {tokenomics.graduationMC.toFixed(3)} Ξ (
                            {compactUSD(tokenomics.graduationMC)})
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p>Total Supply</p>
                          <p className="text-right">
                            {compactFormatter(totalSupply)}
                          </p>
                        </div>
                      </div>
                    </div> */}
                  <div className="col-span-2 h-px bg-subtext"></div>
                  {/* <div className="col-span-2 mx-auto w-fit text-sm text-secondary-foreground">
                      <p>
                        Creation Fee:{" "}
                        <span className="float-right ml-4">
                          {compactFormatter(tokenomics.creationFee)}Ξ
                        </span>
                      </p>
                      <p>
                        Buy amount price:{" "}
                        <span className="float-right ml-4">
                          {compactFormatter(
                            tokenomics.buyAmountPriceWei / 1e18
                          )}
                          Ξ
                        </span>
                      </p>
                      <p>
                        Total:{" "}
                        <span className="float-right ml-4">
                          {compactFormatter(
                            tokenomics.creationFee +
                              tokenomics.buyAmountPriceWei / 1e18
                          )}
                          Ξ (
                          {compactUSD(
                            tokenomics.creationFee +
                              tokenomics.buyAmountPriceWei / 1e18
                          )}
                          )
                        </span>
                      </p>
                    </div> */}
                  <div className="col-span-2 mt-4 flex justify-center gap-4">
                    {!!wallet ? (
                      <>
                        <Button
                          type="submit"
                          className="m-auto px-8"
                          disabled={isLoading}
                          isLoading={isLoading}
                          onClick={(e) => {
                            props.setFieldValue("buy", false);
                          }}
                        >
                          Launch for free
                        </Button>

                        <Dialog
                          open={buyTokenModal}
                          onOpenChange={(open) => setBuyTokenModal(open)}
                        >
                          <DialogTrigger asChild>
                            <Button
                              type="button"
                              className="m-auto px-8"
                              disabled={isLoading}
                              isLoading={isLoading}
                            >
                              Launch & Buy
                            </Button>
                          </DialogTrigger>
                          <DialogContent>
                            <DialogHeader>
                              <DialogTitle className="text-md">
                                Launch and Buy Coin
                              </DialogTitle>
                              <DialogDescription>
                                How much do you want to buy as the Dev?
                              </DialogDescription>
                            </DialogHeader>
                            <div className="flex items-center gap-4">
                              <HandeledInput
                                className="w-full text-md"
                                placeholder="Amount"
                                type="number"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.tokensToBuy}
                                name="tokensToBuy"
                                error={
                                  props.errors.tokensToBuy
                                    ? props.errors.tokensToBuy
                                    : null
                                }
                              />
                              <Button
                                type="button"
                                size="md"
                                onClick={(e) => {
                                  props.setFieldValue("buy", true);
                                  props.submitForm();
                                  setBuyTokenModal(false);
                                }}
                              >
                                Launch & Buy
                              </Button>
                            </div>
                            <p className="text-sm text-secondary-foreground">
                              Buy amount price:{" "}
                              {compactFormatter(
                                tokenomics.buyAmountPriceWei / 1e18
                              )}
                              Ξ
                            </p>
                          </DialogContent>
                        </Dialog>
                      </>
                    ) : (
                      <Button
                        type="submit"
                        className="m-auto px-8"
                        onClick={(e) => {
                          e.preventDefault();
                          login();
                        }}
                      >
                        Connect Wallet
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateAToken;
