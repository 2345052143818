import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}
export interface HandledInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string | null;
  rootClassName?: string | null;
  label?: string;
  optional?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "text-nd flex h-9 w-full rounded-md border border-input bg-primary px-3 py-1 shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
const HandeledInput = React.forwardRef<HTMLInputElement, HandledInputProps>(
  ({ error, rootClassName, label, optional, ...props }, ref) => {
    return (
      <div className={cn(rootClassName, cn("w-full"))}>
        <div className="mb-1">
          {label}
          {optional ? (
            <span className="text-md text-muted-foreground"> (optional)</span>
          ) : null}
        </div>
        <Input
          ref={ref}
          {...props}
          className={cn(props.className, error && "border-red")}
        />
        {error ? <p className="text-sm text-red">{error}</p> : null}
      </div>
    );
  }
);
export const InvisibleInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    return (
      <Input
        ref={ref}
        {...props}
        className={cn(
          "rounded-none border-none bg-transparent p-0 outline-0 !ring-transparent",
          props.className
        )}
      />
    );
  }
);

Input.displayName = "Input";
export interface TextAreaProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "text-nd flex h-20 w-full rounded-md border border-input bg-primary px-3 py-1 shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
TextArea.displayName = "TextArea";
export interface HandeledTextAreaProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  error?: string | null;
  rootClassName?: string | null;
  label?: string;
  optional?: boolean;
}
const HandeledTextArea = React.forwardRef<
  HTMLTextAreaElement,
  HandeledTextAreaProps
>(({ error, label, optional, ...props }, ref) => {
  return (
    <div className={props.rootClassName || ""}>
      {label}
      {optional ? (
        <span className="text-md text-muted-foreground"> (optional)</span>
      ) : null}
      <TextArea
        ref={ref}
        {...props}
        className={cn(props.className, error && "border-red")}
      />
      {error ? <p className="text-red">{error}</p> : null}
    </div>
  );
});
export { HandeledInput, HandeledTextArea, Input, TextArea };
