import SearchIcon from "@/assets/SearchIcon";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent } from "@/components/ui/popover";
import { stonkClient } from "@/Utils/stonkClient";
import { PopoverTrigger } from "@radix-ui/react-popover";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../ui/Loader";

type Props = {
  type: "app" | "dev";
};
const Search = ({ type }: Props) => {
  const [query, setQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [popover, setPopover] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    setSearching(true);
    setPopover(true);
    try {
      const res = await stonkClient(`search/`, {
        params: { q: query },
      });
      if (!res.data.data || res.data.data.length == 0)
        throw new Error("No match found");
      const match = res.data.data[0];
      if (match.type === "token") {
        navigate(`/apps/${match.address}`);
      } else if (match.type === "user") {
        navigate(`/devs/${match.address}`);
      } else {
        throw new Error("No match found");
      }
      setPopover(false);
      setNotFound(false);
    } catch (error) {
      console.error(error);
      setNotFound(true);
      setTimeout(() => setPopover(false), 2000);
    }
    setSearching(false);
  };
  return (
    <div className="w-full">
      <div className="flex w-full items-center rounded-full bg-[#202327] px-4">
        <SearchIcon className="h-5 w-5 fill-subtext" />
        <Input
          type="text"
          placeholder={`Search a${type === "app" ? "n App" : " Dev"}`}
          className="h-full border-none bg-inherit p-2 text-md outline-none placeholder:text-subtext focus:border-none focus:shadow-none focus:ring-0 focus-visible:ring-0"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleSearch}
        />
      </div>
      <Popover open={popover} onOpenChange={(open) => setPopover(open)}>
        <PopoverTrigger className="absolute"></PopoverTrigger>
        <PopoverContent className="" align="start">
          {searching && <Loader />}
          {!searching && notFound && (
            <div className="text-md">No match found</div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default Search;
