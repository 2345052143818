import { Toaster } from "@/components/ui/toaster";
import usePreferences from "@/hooks/usePreferences";
import { howItWorksOpenAtom } from "@/state/howItWorks";
import { privyConfig, queryClient, wagmiConfig } from "@/Utils/configs";
import { PrivyProvider } from "@privy-io/react-auth";
import { WagmiProvider } from "@privy-io/wagmi";
import { Theme } from "@radix-ui/themes";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAtom } from "jotai";
import { PostHogProvider } from "posthog-js/react";
import React from "react";

const options = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
};

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const [howItWorksOpen, setHowItWorksOpen] = useAtom(howItWorksOpenAtom);
  const { preferences, updatePreferences } = usePreferences();

  // useEffect(() => {
  //   if (!!preferences && !preferences.howItWorksSeen) {
  //     setHowItWorksOpen(true);
  //     updatePreferences({ howItWorksSeen: true });
  //   }
  // }, [preferences, updatePreferences]);

  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_KEY}
      options={options}
    >
      <PrivyProvider
        appId={import.meta.env.VITE_PRIVY_APP_ID}
        config={privyConfig}
      >
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={wagmiConfig}>
            <Theme accentColor="amber">
              {children} <Toaster />
              {/* <HowItWorks open={howItWorksOpen} setOpen={setHowItWorksOpen} /> */}
            </Theme>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="bottom-left"
            />
          </WagmiProvider>
        </QueryClientProvider>
      </PrivyProvider>
    </PostHogProvider>
  );
};

export { Wrapper };
