import CurlyArrow from "@/assets/CurlyArrow";
import LandingIllustration from "@/assets/landingIllustration.webp";
import LogoBig from "@/assets/logo_big.png";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <div className="flex min-h-full flex-col items-center px-4 py-8 text-white">
      <img
        className="mb-4"
        src={LogoBig}
        alt="ProductDotFun Logo"
        width={150}
      />
      <h1 className="mb-2 text-center font-title text-2xl font-bold lg:text-4xl">
        Discover the next breakthrough products
      </h1>
      <p className="mb-4 text-center text-base text-gray-400 lg:text-xl">
        Yolo in new ideas and profit when they become mainstream
      </p>
      <div className="relative flex items-center">
        <CurlyArrow className="absolute -translate-x-24 -rotate-12" />
        <Link to="/apps/top">
          <Button className="rounded-full bg-white text-black">
            Start Trading
          </Button>
        </Link>
      </div>
      <img src={LandingIllustration} width={300} alt="Landing Illustration" />
      <div className="relative">
        <div className="absolute flex -translate-x-8 -translate-y-8 -rotate-12 items-center gap-2">
          <div className="text-lg underline">for Builders</div>
          <span className="text-2xl">🏗️</span>
        </div>
        <h3 className="mb-2 text-center font-title text-xl font-bold lg:text-3xl">
          Tokenize your products, crowdfund your idea & build your community
        </h3>
      </div>
      <p className="mb-4 text-center text-base text-gray-400 lg:text-xl">
        They are your evangelist, first users and initial backers
      </p>
      <Link to="/apps/create">
        <Button
          variant="outline"
          className="rounded-full hover:border-white hover:bg-white hover:text-black"
        >
          Tokenize your product in a minute
        </Button>
      </Link>
    </div>
  );
};

export default Landing;
