import { usePrivy } from "@privy-io/react-auth";
import Cookies from "js-cookie";

const useWallet = () => {
  const { user, getAccessToken } = usePrivy();
  const getSignature = async () => {
    await getAccessToken();
    return Cookies.get("privy-id-token");
  };
  return user && user.wallet?.address
    ? { address: user.wallet?.address as `0x${string}`, getSignature }
    : null;
};

export default useWallet;
