import displayAddress from "@/Utils/displayAddress";
import CopyTooltip from "@/components/CopyTooltip";
import useFollow from "@/hooks/useFollow";
import useWallet from "@/hooks/useWallet";
import { cn } from "@/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import Image from "./Image";
import SocialLink, { SocialProps } from "./SocialLink";
import { Button } from "./ui/button";

interface ProfileCardProps {
  username: string;
  address: `0x${string}`;
  avatarUrl?: string;
  is_nsfw: boolean;
  follows?: boolean;
  socials?: SocialProps[];
}

interface ProfileCardDetailedProps extends ProfileCardProps {
  bio: string;
  coinsCreated: number;
  coinsHeld: number;
  followers: number;
  following: number;
  score: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const socialsDummy: SocialProps[] = [
  {
    site: "twitter",
    link: "https://twitter.com/",
  },
  {
    site: "instagram",
    link: "https://instagram.com/",
  },
];

export const ProfileCard = ({
  username,
  address,
  avatarUrl,
  is_nsfw,
  follows,
  socials = [],
}: ProfileCardProps) => {
  const wallet = useWallet();
  const follow = useFollow();
  const queryClient = useQueryClient();

  const handleFollow = async () => {
    await follow({
      followee_address: address,
      follow: !follows,
    });
    queryClient.invalidateQueries({ queryKey: ["explorer"] });
  };

  return (
    <div className="flex items-center p-2">
      <div className="flex flex-1 flex-row">
        <Link to={`/devs/${address}`}>
          <Image
            variant="profile"
            src={avatarUrl || ""}
            alt="Profile image"
            isNsfw={is_nsfw}
            address={address}
            className="mr-2 h-[40px] w-[40px] rounded-full"
          />
        </Link>
        <div>
          <div className="flex items-center gap-1">
            <Link to={`/devs/${address}`}>
              <h4 className="text-md font-normal">@{username}</h4>
            </Link>
            {socials.map((social) => (
              <SocialLink
                key={social.site}
                site={social.site}
                link={social.link}
              />
            ))}
          </div>
          <span className="cursor-pointer">
            <CopyTooltip
              side="right"
              tooltipText="Copy"
              textToCopy={address ?? ""}
            >
              <h5 className="text-md text-subtext">
                {displayAddress(address)}
              </h5>
            </CopyTooltip>
          </span>
        </div>
      </div>

      {wallet?.address !== address && (
        <Button
          className={cn("group", { "bg-white": !follows })}
          onClick={handleFollow}
          size="md"
          variant={follows ? "outline" : "default"}
        >
          {follows ? (
            <>
              <span className="group-hover:hidden">Following</span>
              <span className="hidden group-hover:inline">Unfollow</span>
            </>
          ) : (
            "Follow"
          )}
        </Button>
      )}
    </div>
  );
};

export const ProfileCardDetailed = (data: ProfileCardDetailedProps) => {
  return (
    <Link to={`/devs/${data.address}`} className="w-full">
      <div className="rounded-2xl border-[1px] border-border p-2">
        <div className="flex flex-col">
          <div className="flex">
            <div className="flex flex-1 flex-row">
              <Image
                variant="profile"
                src={data.avatarUrl || ""}
                alt="Profile image"
                isNsfw={data.is_nsfw}
                address={data.address}
                className="mr-2 h-16 w-16 rounded-full"
              />
              <div>
                <div className="flex items-center gap-2">
                  <h4 className="text-md font-normal">{data.username}</h4>
                  {(data.socials ?? []).map((social) => (
                    <SocialLink
                      key={social.site}
                      site={social.site}
                      link={social.link}
                    />
                  ))}
                </div>
                <p className="text-sm text-accent-blue">
                  {displayAddress(data.address)}
                </p>
                <p className="mt-1 text-sm text-accent-green">
                  Coins Created: {data.coinsCreated || 0}, Held:{" "}
                  {data.coinsHeld || 0}
                </p>
                <p className="text-sm text-accent-green">
                  Following: {data.following}, Followers: {data.followers}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <p className="font-bold text-accent underline decoration-accent decoration-1 underline-offset-4">
                {data.score}
              </p>
              {/* <button className="px-4 py-1 font-normal text-black bg-gray-200 rounded-full">
              Follow
            </button> */}
            </div>
          </div>
          {/* <p className="text-md text-subtext">{data.bio}</p> */}
        </div>
      </div>
    </Link>
  );
};
