import { bondingCurveGrowthRate } from "@/Utils/PlatformMath/getTokenomics";
import * as yup from "yup";
export function checkIfFilesAreTooBig(files?: File[]): boolean {
  let valid = true;
  if (files) {
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        valid = false;
      }
    });
  }
  return valid;
}

export const imageValidation = yup
  .mixed<File | string>()
  .test("fileFormat", "Only valid images are allowed", (value) => {
    if (typeof value === "string") {
      return true;
    }
    if (value) {
      // return validFileTypes.includes(value.type);
      return value.type.startsWith("image/");
    }
    return true;
  })
  .test("fileSize", "File size must be less than 3MB", (value) => {
    if (typeof value === "string") {
      return true;
    }
    if (value) {
      return value.size <= 3145728;
    }
    return true;
  });
export const CoinCreateSchema = yup.object().shape({
  name: yup.string().required().min(3).max(50),
  ticker: yup
    .string()
    .required()
    .min(3)
    .max(10)
    .matches(/^[A-Z0-9]{3,10}$/, "Only letters and numbers allowed"),
  short_description: yup.string().max(100),
  description: yup.string().max(1000),
  twitter: yup.string().url(),
  telegram: yup.string().url(),
  website: yup
    .string()
    .url("Website must be a valid URL (https://example.com/)"),
  // other: yup.string().url(),
  image: imageValidation.required("Image is required"),
  curveType: yup.string().oneOf(Object.keys(bondingCurveGrowthRate)),
  graduation: yup
    .number()
    .max(660000000, "Cannot be more than 660M")
    .min(100000000, "Cannot be less than 100M"),
  tokensToBuy: yup
    .number()
    .max(200000000, "Creator cannot buy more than 20% of the total supply")
    .min(0, "Invalid amount"),
});
export const ReplyValidationSchema = yup.object().shape({
  comment: yup.string().required().max(1000),
  image: imageValidation.nullable(),
});
